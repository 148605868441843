import AdditionalApi from "../../api/additional";

export const state = {
  additionalData: {},
  additionalFilter: {},
}

export const mutations = {
  setAdditionalData(state, data) {
    state.additionalData = data;
  },
  setAdditionalFilter(state, filter) {
    state.additionalFilter = filter;
  },
}

export const actions = {
  async loadAdditionalData({ commit, state }) {
    const filter = state.additionalFilter;
    return await AdditionalApi.getAdditionalList(filter).then((response) => {
      commit("setAdditionalData", response.data);
    });
  },
  submitAdditionalFilter({ commit }, filter) {
    commit("setAdditionalFilter", filter);
  },
}
