import Inputmask from "inputmask";

export const InputMask = {
  bind: function (el, binding) {
    var maskOpts = binding.value;
        maskOpts.showMaskOnHover = false;
        maskOpts.autoUnmask = true;
        maskOpts.clearIncomplete = true;
    Inputmask(maskOpts).mask(el);
  },
  unbind: function(el) {
    Inputmask.remove(el);
  }
};
