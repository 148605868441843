import axiosStatic from "axios";
import axios from "../plugins/axios";

const API_URL = "/api/entertainment";

class EntertainmentApi {
  getEntertainmentList(params) {
    return axios().get(API_URL, { params: params });
  }

  getEntertainments(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getEntertainment(entertainId) {
    return axios().get(API_URL + `/${entertainId}`);
  }

  exportEntertainments(params) {
    const source = axiosStatic.CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel();
    }, 60000);

    return axios().get(API_URL + `/export`, {
      params: params,
      responseType: "blob",
      cancelToken: source.token,
    }).then(response => {
      clearTimeout(timeout);

      return response
    });
  }

  printEntertainment(entertainId) {
    return axios().get(API_URL + `/print/${entertainId}`, {
      responseType: "blob",
    });
  }

  createEntertainment(data) {
    return axios().post(API_URL, data);
  }

  updateEntertainment(entertainId, data) {
    return axios().put(API_URL + `/${entertainId}`, data);
  }

  deleteEntertainment(entertainId) {
    return axios().delete(API_URL + `/${entertainId}`);
  }
}

export default new EntertainmentApi();
