import RoomRateApi from "../../api/room-rate";

export const state = {
  roomRateData: {},
  roomRateFilter: {},
}

export const mutations = {
  setRoomRateData(state, data) {
    state.roomRateData = data;
  },
  setRoomRateFilter(state, filter) {
    state.roomRateFilter = filter;
  },
}

export const actions = {
  async loadRoomRateData({ commit, state }) {
    const filter = state.roomRateFilter;
    return await RoomRateApi.getRoomRateList(filter).then((response) => {
      commit("setRoomRateData", response.data);
    });
  },
  submitRoomRateFilter({ commit }, filter) {
    commit("setRoomRateFilter", filter);
  },
}
