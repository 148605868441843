import axios from "../plugins/axios";

const API_URL = "/api/additional-rate";

class AdditionalRateApi {
  getAdditionalRateList(params) {
    return axios().get(API_URL, { params: params });
  }

  getAdditionalRates(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getAdditionalRate(rateId) {
    return axios().get(API_URL + `/${rateId}`);
  }

  createAdditionalRate(data) {
    return axios().post(API_URL, data);
  }

  updateAdditionalRate(rateId, data) {
    return axios().put(API_URL + `/${rateId}`, data);
  }
}

export default new AdditionalRateApi();
