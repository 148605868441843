import CompanyApi from "../../api/company";
import ContactApi from "../../api/contact";

export const state = {
  locationData: {},
  locationFilter: {},
}

export const mutations = {
  setLocationData(state, data) {
    state.locationData = data;
  },
  setLocationFilter(state, filter) {
    state.locationFilter = filter;
  },
}

export const actions = {
  async loadLocationData({ commit, state }) {
    const payload = JSON.parse(JSON.stringify(state.locationFilter));
    
    let exceptFilter = ['customLocation', 'type', 'userPlace', 'searchPlace', 'searchAddress'];
    if (payload.customLocation == "1") {
      exceptFilter = [...exceptFilter, ...['country', 'province', 'city']];
    } else {
      exceptFilter = [...exceptFilter, ...['latitude', 'longitude']];
    }

    let filter = {};
    for (let item in payload) {
      if (!exceptFilter.includes(item))
        filter[item] = payload[item];
    }

    if (filter.mode == "contact") {
      return await ContactApi.getContacts(filter).then((response) => {
        commit("setLocationData", response.data);
      });
    } else {
      return await CompanyApi.getCompanies(filter).then((response) => {
        commit("setLocationData", response.data);
      });
    }
  },
  submitLocationFilter({ commit }, filter) {
    commit("setLocationFilter", filter);
  },
}
