import OfferingApi from "../../api/offering";
import CancelApi from "../../api/cancellation";

export const state = {
  offeringData: {},
  offeringFilter: {},
}

export const mutations = {
  setOfferingData(state, data) {
    state.offeringData = data;
  },
  setOfferingFilter(state, filter) {
    state.offeringFilter = filter;
  },
}

export const actions = {
  async loadOfferingData({ commit, state }) {
    const filter = state.offeringFilter;
    return await OfferingApi.getOfferingList(filter).then((response) => {
      commit("setOfferingData", response.data);
    });
  },
  async loadOfferingCancel({ commit, state }) {
    const filter = state.offeringFilter;
    return await CancelApi.getCancelOfferings(filter).then((response) => {
      commit("setOfferingData", response.data);
    });
  },
  submitOfferingFilter({ commit }, filter) {
    commit("setOfferingFilter", filter);
  },
}
