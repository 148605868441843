import RoomRentalApi from "../../api/room-rental";

export const state = {
  roomRentalData: {},
  roomRentalFilter: {},
}

export const mutations = {
  setRoomRentalData(state, data) {
    state.roomRentalData = data;
  },
  setRoomRentalFilter(state, filter) {
    state.roomRentalFilter = filter;
  },
}

export const actions = {
  async loadRoomRentalData({ commit, state }) {
    const filter = state.roomRentalFilter;
    return await RoomRentalApi.getRoomRentalList(filter).then((response) => {
      commit("setRoomRentalData", response.data);
    });
  },
  submitRoomRentalFilter({ commit }, filter) {
    commit("setRoomRentalFilter", filter);
  },
}
