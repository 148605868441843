import axios from "../plugins/axios";

const API_URL = "/api/segment";

class SegmentApi {
  getSegmentList(params) {
    return axios().get(API_URL, { params: params });
  }

  getSegments(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getSegmentOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  async getSegmentGroupOption(params) {
    return await axios()
      .get(API_URL + "-group", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getSegment(segmentId) {
    return axios().get(API_URL + `/${segmentId}`);
  }

  createSegment(data) {
    return axios().post(API_URL, data);
  }

  updateSegment(segmentId, data) {
    return axios().put(API_URL + `/${segmentId}`, data);
  }

  deleteSegment(segmentId) {
    return axios().delete(API_URL + `/${segmentId}`);
  }
}

export default new SegmentApi();
