export const state = {
  eventTypeFilter: {},
  packageFilter: {},
  onhandBanquetRoomFilter: {},
  industryFilter: {},
  marketSegmentFilter: {},
  venueFilter: {},
  salesIndividualFilter: {},
  salesActivityFilter: {},
  salesActivitySummaryFilter: {},
  salesActivitiesSummaryFilter: {},
  salesRevenueFilter: {},
  companyRevenueFilter: {},
  forecastSalesRevenueFilter: {},
  forecastPropertyRevenueFilter: {},
  groupEventPaceFilter: {},
  banquetFilter: {},
  groupDataFilter: {},
  dailyRevenueByStatusFilter: {},
}

export const mutations = {
  setEventTypeFilter(state, filter) {
    state.eventTypeFilter = filter;
  },
  setPackageFilter(state, filter) {
    state.packageFilter = filter;
  },
  setOnhandBanquetRoomFilter(state, filter) {
    state.onhandBanquetRoomFilter = filter;
  },
  setIndustryFilter(state, filter) {
    state.industryFilter = filter;
  },
  setMarketSegmentFilter(state, filter) {
    state.marketSegmentFilter = filter;
  },
  setVenueFilter(state, filter) {
    state.venueFilter = filter;
  },
  setSalesIndividualFilter(state, filter) {
    state.salesIndividualFilter = filter;
  },
  setSalesActivitySummaryFilter(state, filter) {
    state.salesActivitySummaryFilter = filter;
  },
  setSalesActivitiesSummaryFilter(state, filter) {
    state.salesActivitiesSummaryFilter = filter;
  },
  setSalesActivityFilter(state, filter) {
    state.salesActivityFilter = filter;
  },
  setSalesRevenueFilter(state, filter) {
    state.salesRevenueFilter = filter;
  },
  setCompanyRevenueFilter(state, filter) {
    state.companyRevenueFilter = filter;
  },
  setForecastSalesRevenueFilter(state, filter) {
    state.forecastSalesRevenueFilter = filter;
  },
  setForecastPropertyRevenueFilter(state, filter) {
    state.forecastPropertyRevenueFilter = filter;
  },
  setGroupEventPaceFilter(state, filter) {
    state.groupEventPaceFilter = filter;
  },
  setBanquetFilter(state, filter) {
    state.banquetFilter = filter;
  },
  setGroupDataFilter(state, filter) {
    state.groupDataFilter = filter;
  },
  setDailyRevenueByStatusFilter(state, filter) {
    state.dailyRevenueByStatusFilter = filter;
  },
}

export const actions = {
  submitEventTypeFilter({ commit }, filter) {
    commit("setEventTypeFilter", filter);
  },
  submitPackageFilter({ commit }, filter) {
    commit("setPackageFilter", filter);
  },
  submitOnhandBanquetRoomFilter({ commit }, filter) {
    commit("setOnhandBanquetRoomFilter", filter);
  },
  submitIndustryFilter({ commit }, filter) {
    commit("setIndustryFilter", filter);
  },
  submitMarketSegmentFilter({ commit }, filter) {
    commit("setMarketSegmentFilter", filter);
  },
  submitVenueFilter({ commit }, filter) {
    commit("setVenueFilter", filter);
  },
  submitSalesIndividualFilter({ commit }, filter) {
    commit("setSalesIndividualFilter", filter);
  },
  submitSalesActivitySummaryFilter({ commit }, filter) {
    commit("setSalesActivitySummaryFilter", filter);
  },
  submitSalesActivitiesSummaryFilter({ commit }, filter) {
    commit("setSalesActivitiesSummaryFilter", filter);
  },
  submitSalesActivityFilter({ commit }, filter) {
    commit("setSalesActivityFilter", filter);
  },
  submitSalesRevenueFilter({ commit }, filter) {
    commit("setSalesRevenueFilter", filter);
  },
  submitCompanyRevenueFilter({ commit }, filter) {
    commit("setCompanyRevenueFilter", filter);
  },
  submitForecastSalesRevenueFilter({ commit }, filter) {
    commit("setForecastSalesRevenueFilter", filter);
  },
  submitForecastPropertyRevenueFilter({ commit }, filter) {
    commit("setForecastPropertyRevenueFilter", filter);
  },
  submitGroupEventPaceFilter({ commit }, filter) {
    commit("setGroupEventPaceFilter", filter);
  },
  submitBanquetFilter({ commit }, filter) {
    commit("setBanquetFilter", filter);
  },
  submitGroupDataFilter({ commit }, filter) {
    commit("setGroupDataFilter", filter);
  },
  submitDailyRevenueByStatusFilter({ commit }, filter) {
    commit("setDailyRevenueByStatusFilter", filter);
  },
}
