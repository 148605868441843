import axios from "../plugins/axios";

const API_URL = "/api/cancellation";

class CancelApi {
  getCancelList(params) {
    return axios().get(API_URL, { params: params });
  }

  getCancels(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getCancelOfferings(params) {
    return axios().get(API_URL + `/offering`, { params: params });
  }

  getCancelConfirms(params) {
    return axios().get(API_URL + `/confirm`, { params: params });
  }
}

export default new CancelApi();
