import axiosStatic from "axios";
import axios from "../plugins/axios";

const API_URL = "/api/otheractivity";

class OtherActApi {
  getOtherActList(params) {
    return axios().get(API_URL, { params: params });
  }

  getOtherActs(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getOtherAct(actId) {
    return axios().get(API_URL + `/${actId}`);
  }

  exportOtherActs(params) {
    const source = axiosStatic.CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel();
    }, 60000);

    return axios().get(API_URL + `/export`, {
      params: params,
      responseType: "blob",
      cancelToken: source.token,
    }).then(response => {
      clearTimeout(timeout);

      return response
    });
  }

  createOtherAct(data) {
    return axios().post(API_URL, data);
  }

  updateOtherAct(actId, data) {
    let formData = new FormData();
    for (var key in data) {
      if (key != "attachments") {
        formData.append(key, data[key]);
      }
    }
    if (data.attachments) {
      for( var i = 0; i < data.attachments.length; i++ ){
        let file = data.attachments[i];
        formData.append('attachments[' + i + ']', file);
      }
    }

    return axios().post(API_URL + `/${actId}`, formData);
  }

  deleteOtherAct(actId) {
    return axios().delete(API_URL + `/${actId}`);
  }
}

export default new OtherActApi();
