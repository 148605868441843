import axiosStatic from "axios";
import axios from "../plugins/axios";

const API_URL = "/api/telemarketing";

class TelemarketApi {
  getTelemarketList(params) {
    return axios().get(API_URL, { params: params });
  }

  getTelemarkets(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getTelemarket(telemarketId) {
    return axios().get(API_URL + `/${telemarketId}`);
  }

  exportTelemarkets(params) {
    const source = axiosStatic.CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel();
    }, 60000);

    return axios().get(API_URL + `/export`, {
      params: params,
      responseType: "blob",
      cancelToken: source.token,
    }).then(response => {
      clearTimeout(timeout);

      return response
    });
  }

  createTelemarket(data) {
    return axios().post(API_URL, data);
  }

  updateTelemarket(telemarketId, data) {
    return axios().put(API_URL + `/${telemarketId}`, data);
  }

  deleteTelemarket(telemarketId) {
    return axios().delete(API_URL + `/${telemarketId}`);
  }
}

export default new TelemarketApi();
