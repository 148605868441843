import axios from "../plugins/axios";

const API_URL = "/api/meeting-rate";

class MeetingRateApi {
  getMeetingRateList(params) {
    return axios().get(API_URL, { params: params });
  }

  getMeetingRates(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getMeetingRate(rateId) {
    return axios().get(API_URL + `/${rateId}`);
  }

  createMeetingRate(data) {
    return axios().post(API_URL, data);
  }

  updateMeetingRate(rateId, data) {
    return axios().put(API_URL + `/${rateId}`, data);
  }
}

export default new MeetingRateApi();
