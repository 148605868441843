import BeoApi from "../../api/beo";

export const state = {
  beoData: {},
  beoFilter: {},
}

export const mutations = {
  setBeoData(state, data) {
    state.beoData = data;
  },
  setBeoFilter(state, filter) {
    state.beoFilter = filter;
  },
}

export const actions = {
  async loadBeoData({ commit, state }) {
    const filter = state.beoFilter;
    return await BeoApi.getBeoPage(filter).then((response) => {
      commit("setBeoData", response.data);
    });
  },
  submitBeoFilter({ commit }, filter) {
    commit("setBeoFilter", filter);
  },
}
