import AuthApi from "../../api/auth";
import MenuApi from "../../api/menu";

const session = JSON.parse(localStorage.getItem("session"));

export const state = {
  authData: session?.auth?.authData || {},
  menuData: session?.auth?.menuData || {},
}

export const mutations = {
  setAuthData(state, data) {
    state.authData = data;
  },
  setMenuData(state, data) {
    state.menuData = data;
  },
}

export const actions = {
  async login({ commit }, user) {
    await AuthApi.login(user).then((response) => {
      const data = response.user;
      data.token = response.token;
      data.admin = data.role_type == "admin";
      const auth = {
        auth: {
          authData: data,
          menuData: [],
        }
      };
      localStorage.setItem("session", JSON.stringify(auth));
      commit("setAuthData", data);

      MenuApi.getMenuRole(data?.role_id)
        .then((response) => {
          auth.auth.menuData = response.data;
          /* update local storage */
          localStorage.setItem("session", JSON.stringify(auth));
          commit("setMenuData", response.data);
        });
    });
  },
  logout({ commit }) {
    commit("setAuthData", {});
    commit("setMenuData", {});
  }
}