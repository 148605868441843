import SalescallApi from "../../api/salescall";

export const state = {
  salescallData: {},
  salescallFilter: {},
}

export const mutations = {
  setSalescallData(state, data) {
    state.salescallData = data;
  },
  setSalescallFilter(state, filter) {
    state.salescallFilter = filter;
  },
}

export const actions = {
  async loadSalescallData({ commit, state }) {
    const filter = state.salescallFilter;
    return await SalescallApi.getSalescallList(filter).then((response) => {
      commit("setSalescallData", response.data);
    });
  },
  submitSalescallFilter({ commit }, filter) {
    commit("setSalescallFilter", filter);
  },
}
