import FoodStallApi from "../../api/food-stall";

export const state = {
  foodStallData: {},
  foodStallFilter: {},
}

export const mutations = {
  setFoodStallData(state, data) {
    state.foodStallData = data;
  },
  setFoodStallFilter(state, filter) {
    state.foodStallFilter = filter;
  },
}

export const actions = {
  async loadFoodStallData({ commit, state }) {
    const filter = state.foodStallFilter;
    return await FoodStallApi.getFoodStallList(filter).then((response) => {
      commit("setFoodStallData", response.data);
    });
  },
  submitFoodStallFilter({ commit }, filter) {
    commit("setFoodStallFilter", filter);
  },
}
