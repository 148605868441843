import axios from "../plugins/axios";

const API_URL = "/api/contact";

class ContactApi {
  getContactList(params) {
    return axios().get(API_URL, { params: params });
  }

  getContacts(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getContact(contactId) {
    return axios().get(API_URL + `/${contactId}`);
  }

  getContactDetail(contactId) {
    return axios().get(API_URL + `/${contactId}`, { params: { details: true } });
  }

  createContact(data) {
    return axios().post(API_URL, data);
  }

  mergeContact(data) {
    return axios().post(API_URL + `/merge`, data);
  }

  updateContact(contactId, data) {
    return axios().put(API_URL + `/${contactId}`, data);
  }

  deleteContact(contactId) {
    return axios().delete(API_URL + `/${contactId}`);
  }

  async getContactOption(params) {
    return await axios()
      .get(API_URL + `/list`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          let text = item.lastname 
            ? item.firstname + " " + item.lastname
            : item.firstname;
          text = item.mobile ? text + " - " + item.mobile : text;
          list.push({
            value: item.id,
            text: text,
          });
        });

        return list;
      });
  }

  async getInterestOption(params) {
    return await axios()
      .get(`/api/contact-interest`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  async getRoleOption(params) {
    return await axios()
      .get(`/api/contact-role`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  async getSalutationOption(params) {
    return await axios()
      .get(`/api/contact-salutation`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.name,
            text: item.name,
          });
        });

        return list;
      });
  }

  async getSourceOption(params) {
    return await axios()
      .get(`/api/contact-source`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }
}

export default new ContactApi();
