import PositionApi from "../../api/position";

export const state = {
  positionData: {},
  positionFilter: {},
}

export const mutations = {
  setPositionData(state, data) {
    state.positionData = data;
  },
  setPositionFilter(state, filter) {
    state.positionFilter = filter;
  },
}

export const actions = {
  async loadPositionData({ commit, state }) {
    const filter = state.positionFilter;
    return await PositionApi.getPositionList(filter).then((response) => {
      commit("setPositionData", response.data);
    });
  },
  submitPositionFilter({ commit }, filter) {
    commit("setPositionFilter", filter);
  },
}
