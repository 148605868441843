import axios from "../plugins/axios";

const API_URL = "/api/room-rate";

class RoomRateApi {
  getRoomRateList(params) {
    return axios().get(API_URL, { params: params });
  }

  getRoomRates(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getRoomRate(rateId) {
    return axios().get(API_URL + `/${rateId}`);
  }

  createRoomRate(data) {
    return axios().post(API_URL, data);
  }

  upsertRoomRate(data) {
    return axios().post(API_URL + `/upsert`, data);
  }

  updateRoomRate(rateId, data) {
    return axios().put(API_URL + `/${rateId}`, data);
  }
}

export default new RoomRateApi();
