import axios from "../plugins/axios";

const API_URL = "/api/industry";

class IndustryApi {
  getIndustryList(params) {
    return axios().get(API_URL, { params: params });
  }

  getIndustries(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getIndustryOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getIndustry(industryId) {
    return axios().get(API_URL + `/${industryId}`);
  }

  createIndustry(data) {
    return axios().post(API_URL, data);
  }

  updateIndustry(industryId, data) {
    return axios().put(API_URL + `/${industryId}`, data);
  }

  deleteIndustry(industryId) {
    return axios().delete(API_URL + `/${industryId}`);
  }
}

export default new IndustryApi();
