import MenuApi from "../../api/menu";

export const state = {
  menuData: {},
  menuFilter: {},
}

export const mutations = {
  setMenuData(state, data) {
    state.menuData = data;
  },
  setMenuFilter(state, filter) {
    state.menuFilter = filter;
  },
}

export const actions = {
  async loadMenuData({ commit, state }) {
    const filter = state.menuFilter;
    return await MenuApi.getMenuList(filter).then((response) => {
      commit("setMenuData", response.data);
    });
  },
  submitMenuFilter({ commit }, filter) {
    commit("setMenuFilter", filter);
  },
}
