export const state = {
  refData: {},
};

export const mutations = {
  submitReference(state, data) {
    state.refData = data;
  },
  clearReference(state) {
    state.refData = {};
  },
};

export const actions = {
  submitReference({ commit }, data) {
    commit("submitReference", data);
  },
  clearReference({ commit }) {
    commit("clearReference");
  },
};
