import axios from "../plugins/axios";

const API_URL = "/api/cancel-reason";

class CancelReasonApi {
  getCancelReasonList(params) {
    return axios().get(API_URL, { params: params });
  }

  getCancelReasons(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getCancelReason(cancelReasonId) {
    return axios().get(API_URL + `/${cancelReasonId}`);
  }

  createCancelReason(data) {
    return axios().post(API_URL, data);
  }

  updateCancelReason(cancelReasonId, data) {
    return axios().put(API_URL + `/${cancelReasonId}`, data);
  }
}

export default new CancelReasonApi();
