import EntertainmentApi from "../../api/entertainment";

export const state = {
  entertainmentData: {},
  entertainmentFilter: {},
}

export const mutations = {
  setEntertainmentData(state, data) {
    state.entertainmentData = data;
  },
  setEntertainmentFilter(state, filter) {
    state.entertainmentFilter = filter;
  },
}

export const actions = {
  async loadEntertainmentData({ commit, state }) {
    const filter = state.entertainmentFilter;
    return await EntertainmentApi.getEntertainmentList(filter).then((response) => {
      commit("setEntertainmentData", response.data);
    });
  },
  submitEntertainmentFilter({ commit }, filter) {
    commit("setEntertainmentFilter", filter);
  },
}
