import axiosStatic from "axios";
import axios from "../plugins/axios";

const API_URL = "/api/salescall";

class SalescallApi {
  getSalescallList(params) {
    return axios().get(API_URL, { params: params });
  }

  getSalescalls(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getSalescall(callId) {
    return axios().get(API_URL + `/${callId}`);
  }

  exportSalescalls(params) {
    const source = axiosStatic.CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel();
    }, 60000);

    return axios().get(API_URL + `/export`, {
      params: params,
      responseType: "blob",
      cancelToken: source.token,
    }).then(response => {
      clearTimeout(timeout);

      return response
    });
  }

  createSalescall(data) {
    return axios().post(API_URL, data);
  }

  updateSalescall(callId, data) {
    return axios().put(API_URL + `/${callId}`, data);
  }

  deleteSalescall(callId) {
    return axios().delete(API_URL + `/${callId}`);
  }
}

export default new SalescallApi();
