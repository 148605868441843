function currencyFormat(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  });
  return formatter.format(value);
}

function numberFormat(value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 0,
  });
  var num = !isNaN(value) ? formatter.format(value) : "";

  return num;
}

function integerOnly(value) {
  if (typeof value !== "string") {
    return value;
  }
  value = value.toString().match(/\d+/g);
  value = value ? value.join("") : 0;
  return value;
}

export {
  currencyFormat,
  numberFormat,
  integerOnly,
}
