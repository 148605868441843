import LeadsApi from "../../api/leads";

export const state = {
  leadsData: {},
  leadsFilter: {},
}

export const mutations = {
  setLeadsData(state, data) {
    state.leadsData = data;
  },
  setLeadsFilter(state, filter) {
    state.leadsFilter = filter;
  },
}

export const actions = {
  async loadLeadsData({ commit, state }) {
    const filter = state.leadsFilter;
    return await LeadsApi.getLeadsList(filter).then((response) => {
      commit("setLeadsData", response.data);
    });
  },
  submitLeadsFilter({ commit }, filter) {
    commit("setLeadsFilter", filter);
  },
}
