import axios from "../plugins/axios";

const API_URL = "/api/position";

class PositionApi {
  getPositionList(params) {
    return axios().get(API_URL, { params: params });
  }

  getPositions(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getPositionOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getPosition(positionId) {
    return axios().get(API_URL + `/${positionId}`);
  }

  createPosition(data) {
    return axios().post(API_URL, data);
  }

  updatePosition(positionId, data) {
    return axios().put(API_URL + `/${positionId}`, data);
  }

  deletePosition(positionId) {
    return axios().delete(API_URL + `/${positionId}`);
  }
}

export default new PositionApi();
