import PackageRateApi from "../../api/package-rate";

export const state = {
  packageRateData: {},
  packageRateFilter: {},
}

export const mutations = {
  setPackageRateData(state, data) {
    state.packageRateData = data;
  },
  setPackageRateFilter(state, filter) {
    state.packageRateFilter = filter;
  },
}

export const actions = {
  async loadPackageRateData({ commit, state }) {
    const filter = state.packageRateFilter;
    return await PackageRateApi.getPackageRateList(filter).then((response) => {
      commit("setPackageRateData", response.data);
    });
  },
  submitPackageRateFilter({ commit }, filter) {
    commit("setPackageRateFilter", filter);
  },
}
