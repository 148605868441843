import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import GmapVue from 'gmap-vue';
import VueMask from "v-mask";
import VueRouter from "vue-router";
import vco from "v-click-outside";
import router from "./router/index";
import Scrollspy from "vue2-scrollspy";
import VueSweetalert2 from "vue-sweetalert2";
import Editor from "@tinymce/tinymce-vue";

import "./design/app.scss";

import globalMixin from "./mixins/global";
import store from "./state/store";

import App from "./app.vue";

import tinymce from "vue-tinymce-editor";

import "./directives";
import "./filters";

Vue.component("editor", Editor);
Vue.component("tinymce", tinymce);
Vue.use(VueRouter);
Vue.use(vco);
Vue.use(Scrollspy);
const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(require("vue-chartist"));
Vue.use(VueSweetalert2);
Vue.use(GmapVue, {
  load: {
    key: "AIzaSyDEbbPbwNtwSMWeUWk1hh00qivlNf4SzpM",
    libraries: "places,geometry",
  },
  installComponents: true,
});
Vue.use(require("vue-moment"));
Vue.mixin(globalMixin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
