import axios from "../plugins/axios";

const API_URL = "/api/event-type";

class EventTypeApi {
  getEventTypeList(params) {
    return axios().get(API_URL, { params: params });
  }

  getEventTypes(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getEventTypeOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getEventType(eventTypeId) {
    return axios().get(API_URL + `/${eventTypeId}`);
  }

  createEventType(data) {
    return axios().post(API_URL, data);
  }

  updateEventType(eventTypeId, data) {
    return axios().put(API_URL + `/${eventTypeId}`, data);
  }

  deleteEventType(eventTypeId) {
    return axios().delete(API_URL + `/${eventTypeId}`);
  }

  getEventForms(params) {
    return axios().get(`/api/event-form`, { params: params });
  }
}

export default new EventTypeApi();
