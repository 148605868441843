import axios from "../plugins/axios";

const API_URL = "/api/contract";

class ContractApi {
  getContractList(params) {
    return axios().get(API_URL, { params: params });
  }

  getContracts(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getContractRates(params) {
    return axios().get(API_URL + `/rates`, { params: params });
  }

  getContract(contractId) {
    return axios().get(API_URL + `/${contractId}`);
  }

  printContract(contractId, data) {
    return axios().post(API_URL + `/print/${contractId}`, data, { responseType: "blob" });
  }

  createContract(data) {
    return axios().post(API_URL, data);
  }

  updateContract(contractId, data) {
    return axios().put(API_URL + `/${contractId}`, data);
  }

  deleteContract(contractId) {
    return axios().delete(API_URL + `/${contractId}`);
  }
}

export default new ContractApi();
