import axios from "../plugins/axios";

const API_URL = "/api/offering";

class OfferingApi {
  getOfferingList(params) {
    return axios().get(API_URL, { params: params });
  }

  getOfferings(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getOffering(offerId) {
    return axios().get(API_URL + `/${offerId}`);
  }

  printOffering(offerId) {
    return axios().get(API_URL + `/print/${offerId}`, {
      responseType: "blob",
    });
  }

  createOffering(data) {
    return axios().post(API_URL, data);
  }

  updateOffering(offerId, data) {
    return axios().put(API_URL + `/${offerId}`, data);
  }

  cancelOffering(offerId, data) {
    return axios().put(API_URL + `/cancel/${offerId}`, data);
  }

  deleteOffering(offerId) {
    return axios().delete(API_URL + `/${offerId}`);
  }

  revertCancel(offerId, data) {
    return axios().put(API_URL + `/cancel/revert/${offerId}`, data);
  }
}

export default new OfferingApi();
