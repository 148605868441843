import axios from "../plugins/axios";

const API_URL = "/api/property-room";

class PropertyRoomApi {
  getPropertyRoomList(params) {
    return axios().get(API_URL, { params: params });
  }

  getPropertyRooms(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getPropertyRoomOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.room_id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getPropertyRoom(roomId) {
    return axios().get(API_URL + `/${roomId}`);
  }

  createPropertyRoom(data) {
    return axios().post(API_URL, data);
  }

  updatePropertyRoom(roomId, data) {
    return axios().put(API_URL + `/${roomId}`, data);
  }

  deletePropertyRoom(roomId) {
    return axios().delete(API_URL + `/${roomId}`);
  }
}

export default new PropertyRoomApi();
