import { mapState, mapGetters, mapActions } from "vuex";

export const layoutComputed = {
  ...mapState("layout", {
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
    loader: (state) => state.loader,
  }),
};

export const layoutMethods = mapActions("layout", [
  "changeLayoutType",
  "changeLayoutWidth",
  "changeLeftSidebarType",
  "changeTopbar",
  "changeLoaderValue",
]);

export const notificationMethods = mapActions("notification", [
  "success",
  "error",
  "clear",
]);

export const authComputed = {
  ...mapState("auth", {
    authData: (state) => state.authData,
    menuData: (state) => state.menuData,
  }),
};
export const authMethods = mapActions("auth", [
  "login",
  "logout",
]);

export const filterComputed = {
  ...mapState("filter", {
    filterData: (state) => state.filterData,
  }),
};
export const filterMethods = mapActions("filter", [
  "submitFilter",
  "clearFilter",
]);

export const referenceComputed = {
  ...mapState("reference", {
    refData: (state) => state.refData,
  }),
};
export const referenceMethods = mapActions("reference", [
  "submitReference",
  "clearReference",
]);

export const companyComputed = {
  ...mapState("company", {
    companyData: (state) => state.companyData,
    companyFilter: (state) => state.companyFilter,
  }),
};
export const companyMethods = mapActions("company", [
  "loadCompanyData",
  "submitCompanyFilter",
]);

export const companyGroupComputed = {
  ...mapState("company", {
    companyGroupData: (state) => state.companyGroupData,
    companyGroupFilter: (state) => state.companyGroupFilter,
  }),
};
export const companyGroupMethods = mapActions("company", [
  "loadCompanyGroupData",
  "submitCompanyGroupFilter",
]);

export const contactComputed = {
  ...mapState("contact", {
    contactData: (state) => state.contactData,
    contactFilter: (state) => state.contactFilter,
  }),
};
export const contactMethods = mapActions("contact", [
  "loadContactData",
  "submitContactFilter",
]);

export const contractComputed = {
  ...mapState("contract", {
    contractData: (state) => state.contractData,
    contractFilter: (state) => state.contractFilter,
  }),
};
export const contractMethods = mapActions("contract", [
  "loadContractData",
  "submitContractFilter",
]);

export const locationComputed = {
  ...mapState("location", {
    locationData: (state) => state.locationData,
    locationFilter: (state) => state.locationFilter,
  }),
};
export const locationMethods = mapActions("location", [
  "loadLocationData",
  "submitLocationFilter",
]);

export const telemarketingComputed = {
  ...mapState("telemarketing", {
    telemarketingData: (state) => state.telemarketingData,
    telemarketingFilter: (state) => state.telemarketingFilter,
  }),
};
export const telemarketingMethods = mapActions("telemarketing", [
  "loadTelemarketingData",
  "submitTelemarketingFilter",
]);

export const salescallComputed = {
  ...mapState("salescall", {
    salescallData: (state) => state.salescallData,
    salescallFilter: (state) => state.salescallFilter,
  }),
};
export const salescallMethods = mapActions("salescall", [
  "loadSalescallData",
  "submitSalescallFilter",
]);

export const entertainmentComputed = {
  ...mapState("entertainment", {
    entertainmentData: (state) => state.entertainmentData,
    entertainmentFilter: (state) => state.entertainmentFilter,
  }),
};
export const entertainmentMethods = mapActions("entertainment", [
  "loadEntertainmentData",
  "submitEntertainmentFilter",
]);

export const complimentComputed = {
  ...mapState("compliment", {
    complimentData: (state) => state.complimentData,
    complimentFilter: (state) => state.complimentFilter,
  }),
};
export const complimentMethods = mapActions("compliment", [
  "loadComplimentData",
  "submitComplimentFilter",
]);

export const otherActComputed = {
  ...mapState("otherActivity", {
    otherActData: (state) => state.otherActData,
    otherActFilter: (state) => state.otherActFilter,
  }),
};
export const otherActMethods = mapActions("otherActivity", [
  "loadOtherActData",
  "submitOtherActFilter",
]);

export const leadsComputed = {
  ...mapState("leads", {
    leadsData: (state) => state.leadsData,
    leadsFilter: (state) => state.leadsFilter,
  }),
};
export const leadsMethods = mapActions("leads", [
  "loadLeadsData",
  "submitLeadsFilter",
]);

export const offeringComputed = {
  ...mapState("offering", {
    offeringData: (state) => state.offeringData,
    offeringFilter: (state) => state.offeringFilter,
  }),
};
export const offeringMethods = mapActions("offering", [
  "loadOfferingData",
  "submitOfferingFilter",
]);

export const confirmComputed = {
  ...mapState("confirmation", {
    confirmData: (state) => state.confirmData,
    confirmFilter: (state) => state.confirmFilter,
  }),
};
export const confirmMethods = mapActions("confirmation", [
  "loadConfirmData",
  "submitConfirmFilter",
]);

export const cancelComputed = {
  ...mapState("cancellation", {
    confCancelData: (state) => state.confCancelData,
    confCancelFilter: (state) => state.confCancelFilter,
    offrCancelData: (state) => state.offrCancelData,
    offrCancelFilter: (state) => state.offrCancelFilter,
  }),
};
export const cancelMethods = mapActions("cancellation", [
  "loadConfCancelData",
  "submitConfCancelFilter",
  "loadOffrCancelData",
  "submitOffrCancelFilter",
]);

export const definiteComputed = {
  ...mapState("definite", {
    definiteData: (state) => state.definiteData,
    definiteFilter: (state) => state.definiteFilter,
  }),
};
export const definiteMethods = mapActions("definite", [
  "loadDefiniteData",
  "submitDefiniteFilter",
]);

export const beoComputed = {
  ...mapState("beo", {
    beoData: (state) => state.beoData,
    beoFilter: (state) => state.beoFilter,
  }),
};
export const beoMethods = mapActions("beo", [
  "loadBeoData",
  "submitBeoFilter",
]);

export const industryComputed = {
  ...mapState("industry", {
    industryData: (state) => state.industryData,
    industryFilter: (state) => state.industryFilter,
  }),
};
export const industryMethods = mapActions("industry", [
  "loadIndustryData",
  "submitIndustryFilter",
]);

export const cancelReasonComputed = {
  ...mapState("cancelReason", {
    cancelReasonData: (state) => state.cancelReasonData,
    cancelReasonFilter: (state) => state.cancelReasonFilter,
  }),
};
export const cancelReasonMethods = mapActions("cancelReason", [
  "loadCancelReasonData",
  "submitCancelReasonFilter",
]);

export const zoneComputed = {
  ...mapState("zone", {
    zoneData: (state) => state.zoneData,
    zoneFilter: (state) => state.zoneFilter,
  }),
};
export const zoneMethods = mapActions("zone", [
  "loadZoneData",
  "submitZoneFilter",
]);

export const areaComputed = {
  ...mapState("area", {
    areaData: (state) => state.areaData,
    areaFilter: (state) => state.areaFilter,
  }),
};
export const areaMethods = mapActions("area", [
  "loadAreaData",
  "submitAreaFilter",
]);

export const segmentComputed = {
  ...mapState("segment", {
    segmentData: (state) => state.segmentData,
    segmentFilter: (state) => state.segmentFilter,
  }),
};
export const segmentMethods = mapActions("segment", [
  "loadSegmentData",
  "submitSegmentFilter",
]);

export const additionalComputed = {
  ...mapState("additional", {
    additionalData: (state) => state.additionalData,
    additionalFilter: (state) => state.additionalFilter,
  }),
};
export const additionalMethods = mapActions("additional", [
  "loadAdditionalData",
  "submitAdditionalFilter",
]);

export const additionalRateComputed = {
  ...mapState("additionalRate", {
    additionalRateData: (state) => state.additionalRateData,
    additionalRateFilter: (state) => state.additionalRateFilter,
  }),
};
export const additionalRateMethods = mapActions("additionalRate", [
  "loadAdditionalRateData",
  "submitAdditionalRateFilter",
]);

export const foodStallComputed = {
  ...mapState("foodStall", {
    foodStallData: (state) => state.foodStallData,
    foodStallFilter: (state) => state.foodStallFilter,
  }),
};
export const foodStallMethods = mapActions("foodStall", [
  "loadFoodStallData",
  "submitFoodStallFilter",
]);

export const foodStallRateComputed = {
  ...mapState("foodStallRate", {
    foodStallRateData: (state) => state.foodStallRateData,
    foodStallRateFilter: (state) => state.foodStallRateFilter,
  }),
};
export const foodStallRateMethods = mapActions("foodStallRate", [
  "loadFoodStallRateData",
  "submitFoodStallRateFilter",
]);

export const positionComputed = {
  ...mapState("position", {
    positionData: (state) => state.positionData,
    positionFilter: (state) => state.positionFilter,
  }),
};
export const positionMethods = mapActions("position", [
  "loadPositionData",
  "submitPositionFilter",
]);

export const eventTypeComputed = {
  ...mapState("eventType", {
    eventTypeData: (state) => state.eventTypeData,
    eventTypeFilter: (state) => state.eventTypeFilter,
  }),
};
export const eventTypeMethods = mapActions("eventType", [
  "loadEventTypeData",
  "submitEventTypeFilter",
]);

export const eventNoteComputed = {
  ...mapState("eventNote", {
    eventNoteData: (state) => state.eventNoteData,
    eventNoteFilter: (state) => state.eventNoteFilter,
  }),
};
export const eventNoteMethods = mapActions("eventNote", [
  "loadEventNoteData",
  "submitEventNoteFilter",
]);

export const packageComputed = {
  ...mapState("package", {
    packageData: (state) => state.packageData,
    packageFilter: (state) => state.packageFilter,
  }),
};
export const packageMethods = mapActions("package", [
  "loadPackageData",
  "submitPackageFilter",
]);

export const packageRateComputed = {
  ...mapState("packageRate", {
    packageRateData: (state) => state.packageRateData,
    packageRateFilter: (state) => state.packageRateFilter,
  }),
};
export const packageRateMethods = mapActions("packageRate", [
  "loadPackageRateData",
  "submitPackageRateFilter",
]);

export const meetingComputed = {
  ...mapState("meeting", {
    meetingData: (state) => state.meetingData,
    meetingFilter: (state) => state.meetingFilter,
  }),
};
export const meetingMethods = mapActions("meeting", [
  "loadMeetingData",
  "submitMeetingFilter",
]);

export const meetingRateComputed = {
  ...mapState("meetingRate", {
    meetingRateData: (state) => state.meetingRateData,
    meetingRateFilter: (state) => state.meetingRateFilter,
  }),
};
export const meetingRateMethods = mapActions("meetingRate", [
  "loadMeetingRateData",
  "submitMeetingRateFilter",
]);

export const meetingCalculationComputed = {
  ...mapState("meetingCalculation", {
    meetingCalculationData: (state) => state.meetingCalculationData,
    meetingCalculationFilter: (state) => state.meetingCalculationFilter,
  }),
};
export const meetingCalculationMethods = mapActions("meetingCalculation", [
  "loadMeetingCalculationData",
  "submitMeetingCalculationFilter",
]);

export const salesComputed = {
  ...mapState("sales", {
    salesData: (state) => state.salesData,
    salesFilter: (state) => state.salesFilter,
  }),
};
export const salesMethods = mapActions("sales", [
  "loadSalesData",
  "submitSalesFilter",
]);

export const mealsMenuComputed = {
  ...mapState("mealsMenu", {
    mealsMenuData: (state) => state.mealsMenuData,
    mealsMenuFilter: (state) => state.mealsMenuFilter,
  }),
};
export const mealsMenuMethods = mapActions("mealsMenu", [
  "loadMealsMenuData",
  "submitMealsMenuFilter",
]);

export const mealsSetComputed = {
  ...mapState("mealsSet", {
    mealsSetData: (state) => state.mealsSetData,
    mealsSetFilter: (state) => state.mealsSetFilter,
  }),
};
export const mealsSetMethods = mapActions("mealsSet", [
  "loadMealsSetData",
  "submitMealsSetFilter",
]);

export const propertyComputed = {
  ...mapState("property", {
    propertyData: (state) => state.propertyData,
    propertyFilter: (state) => state.propertyFilter,
  }),
};
export const propertyMethods = mapActions("property", [
  "loadPropertyData",
  "submitPropertyFilter",
]);

export const propertyMroomComputed = {
  ...mapState("propertyMroom", {
    propertyMroomData: (state) => state.propertyMroomData,
    propertyMroomFilter: (state) => state.propertyMroomFilter,
  }),
};
export const propertyMroomMethods = mapActions("propertyMroom", [
  "loadPropertyMroomData",
  "submitPropertyMroomFilter",
]);

export const propertyRoomComputed = {
  ...mapState("propertyRoom", {
    propertyRoomData: (state) => state.propertyRoomData,
    propertyRoomFilter: (state) => state.propertyRoomFilter,
  }),
};
export const propertyRoomMethods = mapActions("propertyRoom", [
  "loadPropertyRoomData",
  "submitPropertyRoomFilter",
]);

export const rateComputed = {
  ...mapState("rate", {
    rateData: (state) => state.rateData,
    rateFilter: (state) => state.rateFilter,
  }),
};
export const rateMethods = mapActions("rate", [
  "loadRateData",
  "submitRateFilter",
]);

export const roleComputed = {
  ...mapState("userRole", {
    roleData: (state) => state.roleData,
    roleFilter: (state) => state.roleFilter,
  }),
};
export const roleMethods = mapActions("userRole", [
  "loadRoleData",
  "submitRoleFilter",
]);

export const roomComputed = {
  ...mapState("room", {
    roomData: (state) => state.roomData,
    roomFilter: (state) => state.roomFilter,
  }),
};
export const roomMethods = mapActions("room", [
  "loadRoomData",
  "submitRoomFilter",
]);

export const roomRentalComputed = {
  ...mapState("roomRental", {
    roomRentalData: (state) => state.roomRentalData,
    roomRentalFilter: (state) => state.roomRentalFilter,
  }),
};
export const roomRentalMethods = mapActions("roomRental", [
  "loadRoomRentalData",
  "submitRoomRentalFilter",
]);

export const roomRateComputed = {
  ...mapState("roomRate", {
    roomRateData: (state) => state.roomRateData,
    roomRateFilter: (state) => state.roomRateFilter,
  }),
};
export const roomRateMethods = mapActions("roomRate", [
  "loadRoomRateData",
  "submitRoomRateFilter",
]);

export const userComputed = {
  ...mapState("user", {
    userData: (state) => state.userData,
    userFilter: (state) => state.userFilter,
  }),
};
export const userMethods = mapActions("user", [
  "loadUserData",
  "submitUserFilter",
]);

export const menuComputed = {
  ...mapState("menu", {
    menuData: (state) => state.menuData,
    menuFilter: (state) => state.menuFilter,
  }),
};
export const menuMethods = mapActions("menu", [
  "loadMenuData",
  "submitMenuFilter",
]);
export const reportComputed = {
  ...mapState("report", {
    eventTypeFilter: (state) => state.eventTypeFilter,
    packageFilter: (state) => state.packageFilter,
    onhandBanquetRoomFilter: (state) => state.onhandBanquetRoomFilter,
    industryFilter: (state) => state.industryFilter,
    marketSegmentFilter: (state) => state.marketSegmentFilter,
    venueFilter: (state) => state.venueFilter,
    salesIndividualFilter: (state) => state.salesIndividualFilter,
    salesActivitySummaryFilter: (state) => state.salesActivitySummaryFilter,
    salesActivitiesSummaryFilter: (state) => state.salesActivitiesSummaryFilter,
    salesActivityFilter: (state) => state.salesActivityFilter,
    salesRevenueFilter: (state) => state.salesRevenueFilter,
    companyRevenueFilter: (state) => state.companyRevenueFilter,
    forecastSalesRevenueFilter: (state) => state.forecastSalesRevenueFilter,
    forecastPropertyRevenueFilter: (state) => state.forecastPropertyRevenueFilter,
    groupEventPaceFilter: (state) => state.groupEventPaceFilter,
    banquetFilter: (state) => state.banquetFilter,
    groupDataFilter: (state) => state.groupDataFilter,
    dailyRevenueByStatusFilter: (state) => state.dailyRevenueByStatusFilter,
  }),
};
export const reportMethods = mapActions("report", [
  "submitEventTypeFilter",
  "submitPackageFilter",
  "submitOnhandBanquetRoomFilter",
  "submitIndustryFilter",
  "submitMarketSegmentFilter",
  "submitVenueFilter",
  "submitSalesIndividualFilter",
  "submitSalesActivitySummaryFilter",
  "submitSalesActivitiesSummaryFilter",
  "submitSalesActivityFilter",
  "submitSalesRevenueFilter",
  "submitCompanyRevenueFilter",
  "submitForecastSalesRevenueFilter",
  "submitForecastPropertyRevenueFilter",
  "submitGroupEventPaceFilter",
  "submitBanquetFilter",
  "submitGroupDataFilter",
  "submitDailyRevenueByStatusFilter",
]);
