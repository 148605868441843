import axios from "../plugins/axios";

const API_URL = "/api/beo";

class BeoApi {
  getBeoPage(params) {
    return axios().get(API_URL, { params: params });
  }

  getBeoList(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getBeo(beoId) {
    return axios().get(API_URL + `/${beoId}`);
  }

  printBeo(beoId) {
    return axios().get(API_URL + `/print/${beoId}`, {
      responseType: "blob",
    });
  }

  printAmend(amendId) {
    return axios().get(API_URL + `/amend/print/${amendId}`, {
      responseType: "blob",
    });
  }

  createBeo(data) {
    return axios().post(API_URL, data);
  }

  createBeoAmend(data) {
    return axios().post(API_URL + `/amend`, data);
  }

  updateBeo(beoId, data) {
    return axios().put(API_URL + `/${beoId}`, data);
  }
}

export default new BeoApi();
