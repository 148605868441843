import axios from "../plugins/axios";

const API_URL = "/api/zone";

class ZoneApi {
  getZoneList(params) {
    return axios().get(API_URL, { params: params });
  }

  getZones(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getZone(zoneId) {
    return axios().get(API_URL + `/${zoneId}`);
  }

  createZone(data) {
    return axios().post(API_URL, data);
  }

  updateZone(zoneId, data) {
    return axios().put(API_URL + `/${zoneId}`, data);
  }

  async getZoneOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }
}

export default new ZoneApi();
