import ConfirmApi from "../../api/confirmation";
import CancelApi from "../../api/cancellation";

export const state = {
  confirmData: {},
  confirmFilter: {},
}

export const mutations = {
  setConfirmData(state, data) {
    state.confirmData = data;
  },
  setConfirmFilter(state, filter) {
    state.confirmFilter = filter;
  },
}

export const actions = {
  async loadConfirmData({ commit, state }) {
    const filter = state.confirmFilter;
    return await ConfirmApi.getConfirmList(filter).then((response) => {
      commit("setConfirmData", response.data);
    });
  },
  async loadConfirmCancel({ commit, state }) {
    const filter = state.offeringFilter;
    return await CancelApi.getCancelConfirms(filter).then((response) => {
      commit("setConfirmData", response.data);
    });
  },
  submitConfirmFilter({ commit }, filter) {
    commit("setConfirmFilter", filter);
  },
}
