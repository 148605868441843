import axios from "../plugins/axios";

const API_URL = "/api/meals-set";

class MealsSetApi {
  getMealsSetList(params) {
    return axios().get(API_URL, { params: params });
  }

  getMealsSets(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getMealsSet(menuId) {
    return axios().get(API_URL + `/${menuId}`);
  }

  createMealsSet(data) {
    return axios().post(API_URL, data);
  }

  updateMealsSet(menuId, data) {
    return axios().put(API_URL + `/${menuId}`, data);
  }
}

export default new MealsSetApi();
