import MealsMenuApi from "../../api/meals-menu";

export const state = {
  mealsMenuData: {},
  mealsMenuFilter: {},
}

export const mutations = {
  setMealsMenuData(state, data) {
    state.mealsMenuData = data;
  },
  setMealsMenuFilter(state, filter) {
    state.mealsMenuFilter = filter;
  },
}

export const actions = {
  async loadMealsMenuData({ commit, state }) {
    const filter = state.mealsMenuFilter;
    return await MealsMenuApi.getMealsMenuList(filter).then((response) => {
      commit("setMealsMenuData", response.data);
    });
  },
  submitMealsMenuFilter({ commit }, filter) {
    commit("setMealsMenuFilter", filter);
  },
}
