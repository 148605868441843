import axios from "../plugins/axios";

const API_URL = "/api/food-stall-rate";

class FoodStallRateApi {
  getFoodStallRateList(params) {
    return axios().get(API_URL, { params: params });
  }

  getFoodStallRates(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getFoodStallRateOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getFoodStallRate(fsrId) {
    return axios().get(API_URL + `/${fsrId}`);
  }

  createFoodStallRate(data) {
    return axios().post(API_URL, data);
  }

  updateFoodStallRate(fsrId, data) {
    return axios().put(API_URL + `/${fsrId}`, data);
  }
}

export default new FoodStallRateApi();
