import ContactApi from "../../api/contact";

export const state = {
  contactData: {},
  contactFilter: {},
}

export const mutations = {
  setContactData(state, data) {
    state.contactData = data;
  },
  setContactFilter(state, filter) {
    state.contactFilter = filter;
  },
}

export const actions = {
  async loadContactData({ commit, state }) {
    const filter = state.contactFilter;
    return await ContactApi.getContactList(filter).then((response) => {
      commit("setContactData", response.data);
    });
  },
  submitContactFilter({ commit }, filter) {
    commit("setContactFilter", filter);
  },
}
