export const state = {
  filterData: {},
};

export const mutations = {
  submitFilter(state, data) {
    state.filterData = data;
  },
  clearFilter(state) {
    state.filterData = {};
  },
};

export const actions = {
  submitFilter({ commit }, data) {
    commit("submitFilter", data);
  },
  clearFilter({ commit }) {
    commit("clearFilter");
  },
};
