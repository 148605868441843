import CompanyApi from "../../api/company";

export const state = {
  companyData: {},
  companyFilter: {},
  companyGroupData: {},
  companyGroupFilter: {},
}

export const mutations = {
  setCompanyData(state, data) {
    state.companyData = data;
  },
  setCompanyFilter(state, filter) {
    state.companyFilter = filter;
  },
  setCompanyGroupData(state, data) {
    state.companyGroupData = data;
  },
  setCompanyGroupFilter(state, filter) {
    state.companyGroupFilter = filter;
  },
}

export const actions = {
  async loadCompanyData({ commit, state }) {
    const filter = state.companyFilter;
    return await CompanyApi.getCompanyList(filter).then((response) => {
      commit("setCompanyData", response.data);
    });
  },
  submitCompanyFilter({ commit }, filter) {
    commit("setCompanyFilter", filter);
  },
  async loadCompanyGroupData({ commit, state }) {
    const filter = state.companyGroupFilter;
    return await CompanyApi.getCompanyGroup(filter).then((response) => {
      commit("setCompanyGroupData", response.data);
    });
  },
  submitCompanyGroupFilter({ commit }, filter) {
    commit("setCompanyGroupFilter", filter);
  },
}
