import SegmentApi from "../../api/segment";

export const state = {
  segmentData: {},
  segmentFilter: {},
}

export const mutations = {
  setSegmentData(state, data) {
    state.segmentData = data;
  },
  setSegmentFilter(state, filter) {
    state.segmentFilter = filter;
  },
}

export const actions = {
  async loadSegmentData({ commit, state }) {
    const filter = state.segmentFilter;
    return await SegmentApi.getSegmentList(filter).then((response) => {
      commit("setSegmentData", response.data);
    });
  },
  submitSegmentFilter({ commit }, filter) {
    commit("setSegmentFilter", filter);
  },
}
