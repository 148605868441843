import axios from "axios";

const API_URL = "/api/auth";
const API_IP = "https://api.ipify.org/?format=json"

class AuthApi {
  async login(form) {
    let ip_address = "";
    await axios.get(API_IP).then((response) => {
      ip_address = response.data ? response.data.ip : "";
    });

    return await axios.post(API_URL, form).then((response) => {
      if (!response.data.token) {
        throw response.data;
      }

      const data = response.data;
      data.user.ip = ip_address;

      return data;
    });
  }
}

export default new AuthApi();