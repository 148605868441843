import UserApi from "../../api/user";

export const state = {
  userData: {},
  userFilter: {},
}

export const mutations = {
  setUserData(state, data) {
    state.userData = data;
  },
  setUserFilter(state, filter) {
    state.userFilter = filter;
  },
}

export const actions = {
  async loadUserData({ commit, state }) {
    const filter = state.userFilter;
    return await UserApi.getUserList(filter).then((response) => {
      commit("setUserData", response.data);
    });
  },
  submitUserFilter({ commit }, filter) {
    commit("setUserFilter", filter);
  },
}
