import axios from "../plugins/axios";

const API_URL = "/api/meeting-calculation";

class MeetingCalculationApi {
  getMeetingCalculationList(params) {
    return axios().get(API_URL, { params: params });
  }

  getMeetingCalculations(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getMeetingCalculation(calcId) {
    return axios().get(API_URL + `/${calcId}`);
  }

  createMeetingCalculation(data) {
    return axios().post(API_URL, data);
  }

  updateMeetingCalculation(calcId, data) {
    return axios().put(API_URL + `/${calcId}`, data);
  }
}

export default new MeetingCalculationApi();
