import RateApi from "../../api/rate";

export const state = {
  rateData: {},
  rateFilter: {},
}

export const mutations = {
  setRateData(state, data) {
    state.rateData = data;
  },
  setRateFilter(state, filter) {
    state.rateFilter = filter;
  },
}

export const actions = {
  async loadRateData({ commit, state }) {
    const filter = state.rateFilter;
    return await RateApi.getRateList(filter).then((response) => {
      commit("setRateData", response.data);
    });
  },
  submitRateFilter({ commit }, filter) {
    commit("setRateFilter", filter);
  },
}
