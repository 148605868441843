import ComplimentApi from "../../api/compliment";

export const state = {
  complimentData: {},
  complimentFilter: {},
}

export const mutations = {
  setComplimentData(state, data) {
    state.complimentData = data;
  },
  setComplimentFilter(state, filter) {
    state.complimentFilter = filter;
  },
}

export const actions = {
  async loadComplimentData({ commit, state }) {
    const filter = state.complimentFilter;
    return await ComplimentApi.getComplimentList(filter).then((response) => {
      commit("setComplimentData", response.data);
    });
  },
  submitComplimentFilter({ commit }, filter) {
    commit("setComplimentFilter", filter);
  },
}
