import PropertyRoomApi  from "../../api/property-room";

export const state = {
  propertyRoomData: {},
  propertyRoomFilter: {},
}

export const mutations = {
  setPropertyRoomData(state, data) {
    state.propertyRoomData = data;
  },
  setPropertyRoomFilter(state, filter) {
    state.propertyRoomFilter = filter;
  },
}

export const actions = {
  async loadPropertyRoomData({ commit, state }) {
    const filter = state.propertyRoomFilter;
    return await PropertyRoomApi.getPropertyRoomList(filter).then((response) => {
      commit("setPropertyRoomData", response.data);
    });
  },
  submitPropertyRoomFilter({ commit }, filter) {
    commit("setPropertyRoomFilter", filter);
  },
}
