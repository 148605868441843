import RoleApi from "../../api/user-role";

export const state = {
  roleData: {},
  roleFilter: {},
}

export const mutations = {
  setRoleData(state, data) {
    state.roleData = data;
  },
  setRoleFilter(state, filter) {
    state.roleFilter = filter;
  },
}

export const actions = {
  async loadRoleData({ commit, state }) {
    const filter = state.roleFilter;
    return await RoleApi.getRoleList(filter).then((response) => {
      commit("setRoleData", response.data);
    });
  },
  submitRoleFilter({ commit }, filter) {
    commit("setRoleFilter", filter);
  },
}
