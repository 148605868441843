import MeetingApi from "../../api/meeting";

export const state = {
  meetingData: {},
  meetingFilter: {},
}

export const mutations = {
  setMeetingData(state, data) {
    state.meetingData = data;
  },
  setMeetingFilter(state, filter) {
    state.meetingFilter = filter;
  },
}

export const actions = {
  async loadMeetingData({ commit, state }) {
    const filter = state.meetingFilter;
    return await MeetingApi.getMeetingList(filter).then((response) => {
      commit("setMeetingData", response.data);
    });
  },
  submitMeetingFilter({ commit }, filter) {
    commit("setMeetingFilter", filter);
  },
}
