import axios from "../plugins/axios";

const API_URL = "/api/leads";

class LeadsApi {
  getLeadsList(params) {
    return axios().get(API_URL, { params: params });
  }

  getLeadsData(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getLeads(leadsId) {
    return axios().get(API_URL + `/${leadsId}`);
  }

  createLeads(data) {
    return axios().post(API_URL, data);
  }

  createFollowUp(data) {
    return axios().post(API_URL + `/followup`, data);
  }

  updateLeads(leadsId, data) {
    return axios().put(API_URL + `/${leadsId}`, data);
  }

  deleteLeads(leadsId) {
    return axios().delete(API_URL + `/${leadsId}`);
  }
}

export default new LeadsApi();
