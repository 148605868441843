import axios from "../plugins/axios";

const API_URL = "/api/area";

class AreaApi {
  getAreaList(params) {
    return axios().get(API_URL, { params: params });
  }

  getAreas(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getArea(areaId) {
    return axios().get(API_URL + `/${areaId}`);
  }

  createArea(data) {
    return axios().post(API_URL, data);
  }

  updateArea(areaId, data) {
    return axios().put(API_URL + `/${areaId}`, data);
  }

  async getAreaOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
            property: item.property_id,
          });
        });

        return list;
      });
  }
}

export default new AreaApi();
