import axiosStatic from "axios";
import axios from "../plugins/axios";

const API_URL = "/api/compliment";

class ComplimentApi {
  getComplimentList(params) {
    return axios().get(API_URL, { params: params });
  }

  getCompliments(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getCompliment(compId) {
    return axios().get(API_URL + `/${compId}`);
  }

  exportCompliments(params) {
    const source = axiosStatic.CancelToken.source();
    const timeout = setTimeout(() => {
      source.cancel();
    }, 60000);

    return axios().get(API_URL + `/export`, {
      params: params,
      responseType: "blob",
      cancelToken: source.token,
    }).then(response => {
      clearTimeout(timeout);

      return response
    });
  }

  printCompliment(compId) {
    return axios().get(API_URL + `/print/${compId}`, {
      responseType: "blob",
    });
  }

  createCompliment(data) {
    return axios().post(API_URL, data);
  }

  updateCompliment(compId, data) {
    return axios().put(API_URL + `/${compId}`, data);
  }

  deleteCompliment(compId) {
    return axios().delete(API_URL + `/${compId}`);
  }
}

export default new ComplimentApi();
