import axios from "../plugins/axios";

const API_URL = "/api/package-rate";

class PackageRateApi {
  getPackageRateList(params) {
    return axios().get(API_URL, { params: params });
  }

  getPackageRates(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getPackageRateOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getPackageRate(rateId) {
    return axios().get(API_URL + `/${rateId}`);
  }

  createPackageRate(data) {
    return axios().post(API_URL, data);
  }

  updatePackageRate(rateId, data) {
    return axios().put(API_URL + `/${rateId}`, data);
  }
}

export default new PackageRateApi();
