import PropertyMroomApi  from "../../api/property-mroom";

export const state = {
  propertyMroomData: {},
  propertyMroomFilter: {},
}

export const mutations = {
  setPropertyMroomData(state, data) {
    state.propertyMroomData = data;
  },
  setPropertyMroomFilter(state, filter) {
    state.propertyMroomFilter = filter;
  },
}

export const actions = {
  async loadPropertyMroomData({ commit, state }) {
    const filter = state.propertyMroomFilter;
    return await PropertyMroomApi.getMroomList(filter).then((response) => {
      commit("setPropertyMroomData", response.data);
    });
  },
  submitPropertyMroomFilter({ commit }, filter) {
    commit("setPropertyMroomFilter", filter);
  },
}
