import axios from "../plugins/axios";

const API_URL = "/api/meals-menu";

class MealsMenuApi {
  getMealsMenuList(params) {
    return axios().get(API_URL, { params: params });
  }

  getMealsMenus(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getMealsMenuOption(params) {
    return await axios()
      .get(API_URL + `/list`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  async getCategoryOption(params) {
    return await axios()
      .get("/api/meals-category", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  async getSubCategoryOption(params) {
    return await axios()
      .get("/api/meals-style", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getMealsMenu(menuId) {
    return axios().get(API_URL + `/${menuId}`);
  }

  createMealsMenu(data) {
    return axios().post(API_URL, data);
  }

  updateMealsMenu(menuId, data) {
    return axios().put(API_URL + `/${menuId}`, data);
  }
}

export default new MealsMenuApi();
