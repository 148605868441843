import ContractApi from "../../api/contract";

export const state = {
  contractData: {},
  contractFilter: {},
}

export const mutations = {
  setContractData(state, data) {
    state.contractData = data;
  },
  setContractFilter(state, filter) {
    state.contractFilter = filter;
  },
}

export const actions = {
  async loadContractData({ commit, state }) {
    const filter = state.contractFilter;
    return await ContractApi.getContractList(filter).then((response) => {
      commit("setContractData", response.data);
    });
  },
  submitContractFilter({ commit }, filter) {
    commit("setContractFilter", filter);
  },
}
