import axios from "../plugins/axios";

const API_URL = "/api/sales";

class SalesApi {
  getSalesList(params) {
    return axios().get(API_URL, { params: params });
  }

  getSales(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getSalesOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          let text = item.firstname + " " + item.lastname;
          let disabled = (params.disabled && item.status == 0) ? true : false;
          if (params.withstatus) {
            text += (item.status == 0) ? " (Not Active)" : "";
          } else if (params.withProp) {
            text += " (" + item.property_id + ")";
          }
          list.push({
            value: item.id,
            text: text,
            prop: item.property_id,
            $isDisabled: disabled,
          });
        });

        return list;
      });
  }

  getSalesById(salesId) {
    return axios().get(API_URL + `/${salesId}`);
  }

  createSales(data) {
    let formData = new FormData();
    for (var key in data) {
      if (data[key] || ["lastname"].includes(key)) {
        formData.append(key, data[key]);
      }
    }

    return axios().post(API_URL, formData);
  }

  updateSales(salesId, data) {
    let formData = new FormData();
    for (var key in data) {
      if (data[key] || ["lastname"].includes(key)) {
        formData.append(key, data[key]);
      }
    }

    return axios().post(API_URL + `/${salesId}`, formData);
  }

  deleteSales(salesId) {
    return axios().delete(API_URL + `/${salesId}`);
  }
}

export default new SalesApi();
