import axios from "../plugins/axios";

const API_URL = "/api/event-note";

class EventNoteApi {
  getEventNoteList(params) {
    return axios().get(API_URL, { params: params });
  }

  getEventNotes(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getEventNote(eventNoteId) {
    return axios().get(API_URL + `/${eventNoteId}`);
  }

  createEventNote(data) {
    return axios().post(API_URL, data);
  }

  updateEventNote(eventNoteId, data) {
    return axios().put(API_URL + `/${eventNoteId}`, data);
  }

  deleteEventNote(eventNoteId) {
    return axios().delete(API_URL + `/${eventNoteId}`);
  }
}

export default new EventNoteApi();
