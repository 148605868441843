import CancelReasonApi from "../../api/cancel-reason";

export const state = {
  cancelReasonData: {},
  cancelReasonFilter: {},
}

export const mutations = {
  setCancelReasonData(state, data) {
    state.cancelReasonData = data;
  },
  setCancelReasonFilter(state, filter) {
    state.cancelReasonFilter = filter;
  },
}

export const actions = {
  async loadCancelReasonData({ commit, state }) {
    const filter = state.cancelReasonFilter;
    return await CancelReasonApi.getCancelReasonList(filter).then((response) => {
      commit("setCancelReasonData", response.data);
    });
  },
  submitCancelReasonFilter({ commit }, filter) {
    commit("setCancelReasonFilter", filter);
  },
}
