import axios from "../plugins/axios";

const API_URL = "/api/company";

class CompanyApi {
  getCompanyList(params) {
    return axios().get(API_URL, { params: params });
  }

  getCompanies(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getCompanyGroup(params) {
    return axios().get(API_URL + `/group`, { params: params });
  }

  getCompany(companyId) {
    return axios().get(API_URL + `/${companyId}`);
  }

  getCompanyDetail(companyId) {
    return axios().get(API_URL + `/${companyId}`, { params: { details: true } });
  }

  createCompany(data) {
    return axios().post(API_URL, data);
  }

  mergeCompany(data) {
    return axios().post(API_URL + `/merge`, data);
  }

  updateCompany(companyId, data) {
    return axios().put(API_URL + `/${companyId}`, data);
  }

  deleteCompany(companyId) {
    return axios().delete(API_URL + `/${companyId}`);
  }
}

export default new CompanyApi();
