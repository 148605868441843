import EventNoteApi from "../../api/event-note";

export const state = {
  eventNoteData: {},
  eventNoteFilter: {},
}

export const mutations = {
  setEventNoteData(state, data) {
    state.eventNoteData = data;
  },
  setEventNoteFilter(state, filter) {
    state.eventNoteFilter = filter;
  },
}

export const actions = {
  async loadEventNoteData({ commit, state }) {
    const filter = state.eventNoteFilter;
    return await EventNoteApi.getEventNoteList(filter).then((response) => {
      commit("setEventNoteData", response.data);
    });
  },
  submitEventNoteFilter({ commit }, filter) {
    commit("setEventNoteFilter", filter);
  },
}
