import axios from "axios";

import router from "../router/index";
import store from "../state/store";

export default () => {
  const instance = axios.create();

  instance.interceptors.request.use(
    (config) => {
      const session = JSON.parse(localStorage.getItem("session")),
            token = session?.auth?.authData?.token || null;
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      const token = response.headers["content-token"];
      const exp = response.headers["content-exp"];

      const session = JSON.parse(localStorage.getItem("session")),
            auth = session?.auth?.authData || null;
      if (token && auth.token) {
        session.auth.authData = Object.assign({}, auth, { 
          token: token,
          exp: parseInt(exp),
        });
        localStorage.setItem("session", JSON.stringify(session));
        store.commit("auth/setAuthData", session.auth.authData);
      }

      return response;
    },
    async (error) => {
      const response = error.response ? error.response : { status: 0 };
      if (axios.isCancel(error)) {
        throw new axios.Cancel("Request Timeout.");
      }

      if (response.status === 401) {
        store.commit("auth/setAuthData", {});
        localStorage.clear();
        router.replace("/login");
      } else if (response.status === 403) {
        router.replace("/");
      } else if (response.status === 500) {
        router.push({ name: "500", params: { "0": "" } });
      }

      return Promise.reject(error);
    }
  );

  return instance;
}
