import DefiniteApi from "../../api/definite";

export const state = {
  definiteData: {},
  definiteFilter: {},
}

export const mutations = {
  setDefiniteData(state, data) {
    state.definiteData = data;
  },
  setDefiniteFilter(state, filter) {
    state.definiteFilter = filter;
  },
}

export const actions = {
  async loadDefiniteData({ commit, state }) {
    const filter = state.definiteFilter;
    return await DefiniteApi.getDefiniteList(filter).then((response) => {
      commit("setDefiniteData", response.data);
    });
  },
  submitDefiniteFilter({ commit }, filter) {
    commit("setDefiniteFilter", filter);
  },
}
