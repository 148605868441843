import TelemarketingApi from "../../api/telemarketing";

export const state = {
  telemarketingData: {},
  telemarketingFilter: {},
}

export const mutations = {
  setTelemarketingData(state, data) {
    state.telemarketingData = data;
  },
  setTelemarketingFilter(state, filter) {
    state.telemarketingFilter = filter;
  },
}

export const actions = {
  async loadTelemarketingData({ commit, state }) {
    const filter = state.telemarketingFilter;
    return await TelemarketingApi.getTelemarketList(filter).then((response) => {
      commit("setTelemarketingData", response.data);
    });
  },
  submitTelemarketingFilter({ commit }, filter) {
    commit("setTelemarketingFilter", filter);
  },
}
