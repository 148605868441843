import responsiveRoute from "./responsive";

export default [
  {
    path: '/',
    name: 'default',
    meta: {
      authRequired: true,
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "dashboard-xs" */ './views-mobile/dashboard/index'),
      // sm key is missing, it falls back to xs
      md: () => import(/* webpackChunkName: "dashboard-md" */ './views/dashboard/default'),
      // lg, xl keys are missing falls back to md
    }),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('./views/utility/404'),
  },
  {
    path: '/500',
    name: '500',
    component: () => import('./views/utility/500'),
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/auth/login'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./views/auth/logout'),
  },
  {
    path: '/company',
    name: 'company',
    meta: {
      authRequired: true,
      scope: 'company',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "company-xs" */ './views-mobile/accounts/company/index'),
      md: () => import(/* webpackChunkName: "company-md" */ './views/accounts/company/index'),
    }),
  },
  {
    path: '/company/:id(\\d+)',
    name: 'company-id',
    meta: {
      authRequired: true,
      scope: 'company',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "company-xs" */ './views-mobile/accounts/company/detail'),
      md: () => import(/* webpackChunkName: "company-md" */ './views/accounts/company/detail'),
    }),
  },
  {
    path: '/company-group',
    name: 'company-group',
    meta: {
      authRequired: true,
      scope: 'company',
    },
    component: () => import('./views/accounts/company/group'),
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      authRequired: true,
      scope: 'contact',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "contact-xs" */ './views-mobile/accounts/contact/index'),
      md: () => import(/* webpackChunkName: "contact-md" */ './views/accounts/contact/index'),
    }),
  },
  {
    path: '/contact/:id(\\d+)',
    name: 'contact-id',
    meta: {
      authRequired: true,
      scope: 'contact',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "contact-xs" */ './views-mobile/accounts/contact/detail'),
      md: () => import(/* webpackChunkName: "contact-md" */ './views/accounts/contact/detail'),
    }),
  },
  {
    path: '/contract',
    name: 'contract',
    meta: {
      authRequired: true,
      scope: 'contract',
    },
    component: () => import('./views/accounts/contract/index'),
  },
  {
    path: '/contract/:id(\\d+)',
    name: 'contract-id',
    meta: {
      authRequired: true,
      scope: 'contract',
    },
    component: () => import('./views/accounts/contract/detail'),
  },
  {
    path: '/location',
    name: 'location',
    meta: {
      authRequired: true,
      scope: 'location',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "location-xs" */ './views-mobile/accounts/location/index'),
      md: () => import(/* webpackChunkName: "location-md" */ './views/accounts/location/index'),
    }),
  },
  {
    path: '/telemarketing',
    name: 'telemarketing',
    meta: {
      authRequired: true,
      scope: 'telemarketing',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "telemarketing-xs" */ './views-mobile/activity/telemarketing/index'),
      md: () => import(/* webpackChunkName: "telemarketing-md" */ './views/activity/telemarketing/index'),
    }),
  },
  {
    path: '/salesplan',
    name: 'salesplan',
    meta: {
      authRequired: true,
      scope: 'salesplan',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "salesplan-xs" */ './views-mobile/activity/salesplan/index'),
      md: () => import(/* webpackChunkName: "salesplan-md" */ './views/activity/salesplan/index'),
    }),
  },
  {
    path: '/entertainment',
    name: 'entertainment',
    meta: {
      authRequired: true,
      scope: 'entertainment',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "entertainment-xs" */ './views-mobile/activity/entertainment/index'),
      md: () => import(/* webpackChunkName: "entertainment-md" */ './views/activity/entertainment/index'),
    }),
  },
  {
    path: '/complimentary',
    name: 'complimentary',
    meta: {
      authRequired: true,
      scope: 'complimentary',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "complimentary-xs" */ './views-mobile/activity/complimentary/index'),
      md: () => import(/* webpackChunkName: "complimentary-md" */ './views/activity/complimentary/index'),
    }),
  },
  {
    path: '/other-activity',
    name: 'other-activity',
    meta: {
      authRequired: true,
      scope: 'other-activity',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "other-activity-xs" */ './views-mobile/activity/other-activity/index'),
      md: () => import(/* webpackChunkName: "other-activity-md" */ './views/activity/other-activity/index'),
    }),
  },
  {
    path: '/leads',
    name: 'leads',
    meta: {
      authRequired: true,
      scope: 'leads',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "leads-xs" */ './views-mobile/qualifying/leads/index'),
      md: () => import(/* webpackChunkName: "leads-md" */ './views/qualifying/leads/index'),
    }),
  },
  {
    path: '/leads/:id(\\d+)',
    name: 'leads-id',
    meta: {
      authRequired: true,
      scope: 'leads',
    },
    component: () => import('./views/qualifying/leads/detail'),
  },
  {
    path: '/leads/convert/:id(\\d+)',
    name: 'leads-convert-id',
    meta: {
      authRequired: true,
      scope: 'leads',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "leads-xs" */ './views-mobile/qualifying/leads/convert'),
      md: () => import(/* webpackChunkName: "leads-md" */ './views/qualifying/leads/convert'),
    }),
  },
  {
    path: '/leads/create',
    name: 'leads-create',
    meta: {
      authRequired: true,
      scope: 'leads',
    },
    component: () => import('./views/qualifying/leads/create'),
  },
  {
    path: '/confirmation',
    name: 'confirmation',
    meta: {
      authRequired: true,
      scope: 'confirmation',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "confirmation-xs" */ './views-mobile/qualifying/confirmation/index'),
      md: () => import(/* webpackChunkName: "confirmation-md" */ './views/qualifying/confirmation/index'),
    }),
  },
  {
    path: '/confirmation/:id(\\d+)',
    name: 'confirmation-id',
    meta: {
      authRequired: true,
      scope: 'confirmation',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "confirmation-xs" */ './views-mobile/qualifying/confirmation/detail'),
      md: () => import(/* webpackChunkName: "confirmation-md" */ './views/qualifying/confirmation/detail'),
    }),
  },
  {
    path: '/confirmation/update/:id(\\d+)',
    name: 'confirmation-update-id',
    meta: {
      authRequired: true,
      scope: 'confirmation',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "confirmation-xs" */ './views-mobile/qualifying/confirmation/update'),
      md: () => import(/* webpackChunkName: "confirmation-md" */ './views/qualifying/confirmation/update'),
    }),
  },
  {
    path: '/offering',
    name: 'offering',
    meta: {
      authRequired: true,
      scope: 'offering',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "offering-xs" */ './views-mobile/qualifying/offering/index'),
      md: () => import(/* webpackChunkName: "offering-md" */ './views/qualifying/offering/index'),
    }),
  },
  {
    path: '/offering/:id(\\d+)',
    name: 'offering-id',
    meta: {
      authRequired: true,
      scope: 'offering',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "offering-xs" */ './views-mobile/qualifying/offering/detail'),
      md: () => import(/* webpackChunkName: "offering-md" */ './views/qualifying/offering/detail'),
    }),
  },
  {
    path: '/offering/create',
    name: 'offering-create',
    meta: {
      authRequired: true,
      scope: 'offering',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "offering-xs" */ './views-mobile/qualifying/offering/create'),
      md: () => import(/* webpackChunkName: "offering-md" */ './views/qualifying/offering/create'),
    }),
  },
  {
    path: '/offering/update/:id(\\d+)',
    name: 'offering-update-id',
    meta: {
      authRequired: true,
      scope: 'offering',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "offering-xs" */ './views-mobile/qualifying/offering/update'),
      md: () => import(/* webpackChunkName: "offering-md" */ './views/qualifying/offering/update'),
    }),
  },
  {
    path: '/offering/confirm/:id(\\d+)',
    name: 'offering-confirm-id',
    meta: {
      authRequired: true,
      scope: 'offering',
    },
    component: () => import('./views/qualifying/offering/confirm'),
  },
  {
    path: '/cancellation',
    name: 'cancellation',
    meta: {
      authRequired: true,
      scope: 'cancellation',
    },
    component: () => import('./views/qualifying/cancellation/index'),
  },
  {
    path: '/cancellation/confirm/:id(\\d+)',
    name: 'cancellation-confirm-id',
    meta: {
      authRequired: true,
      scope: 'cancellation',
    },
    component: () => import('./views/qualifying/cancellation/detail-confirm'),
  },
  {
    path: '/cancellation/offering/:id(\\d+)',
    name: 'cancellation-offering-id',
    meta: {
      authRequired: true,
      scope: 'cancellation',
    },
    component: () => import('./views/qualifying/cancellation/detail-offering'),
  },
  {
    path: '/definite',
    name: 'definite',
    meta: {
      authRequired: true,
      scope: 'definite',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "definite-xs" */ './views-mobile/closing/definite/index'),
      md: () => import(/* webpackChunkName: "definite-md" */ './views/closing/definite/index'),
    }),
  },
  {
    path: '/definite/:id(\\d+)',
    name: 'definite-id',
    meta: {
      authRequired: true,
      scope: 'definite',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "definite-xs" */ './views-mobile/closing/definite/detail'),
      md: () => import(/* webpackChunkName: "definite-md" */ './views/closing/definite/detail'),
    }),
  },
  {
    path: '/definite/update/:id(\\d+)',
    name: 'definite-update-id',
    meta: {
      authRequired: true,
      scope: 'definite',
    },
    component: () => import('./views/closing/definite/actual'),
  },
  {
    path: '/beo',
    name: 'beo',
    meta: {
      authRequired: true,
      scope: 'beo',
    },
    component: () => import('./views/closing/beo/index'),
  },
  {
    path: '/beo/:id(\\d+)',
    name: 'beo-id',
    meta: {
      authRequired: true,
      scope: 'beo',
    },
    component: () => import('./views/closing/beo/detail'),
  },
  {
    path: '/beo/create/:id(\\d+)',
    name: 'beo-create-id',
    meta: {
      authRequired: true,
      scope: 'beo',
    },
    component: () => import('./views/closing/beo/create'),
  },
  {
    path: '/beo/update/:id(\\d+)',
    name: 'beo-update-id',
    meta: {
      authRequired: true,
      scope: 'beo',
    },
    component: () => import('./views/closing/beo/update'),
  },
  {
    path: '/function-diary',
    name: 'function-diary',
    meta: {
      authRequired: true,
      scope: 'function-diary',
    },
    component: responsiveRoute({
      xs: () => import(/* webpackChunkName: "definite-xs" */ './views-mobile/report/function-diary/index'),
      md: () => import(/* webpackChunkName: "definite-md" */ './views/report/function-diary/index'),
    }),
  },
  {
    path: '/report/event-type',
    name: 'report-event-type',
    meta: {
      authRequired: true,
      scope: 'report-event-type',
    },
    component: () => import('./views/report/event-type/index'),
  },
  {
    path: '/report/package',
    name: 'report-package',
    meta: {
      authRequired: true,
      scope: 'report-package',
    },
    component: () => import('./views/report/package/index'),
  },
  {
    path: '/report/onhand-banquet-room',
    name: 'report-onhand-banquet-room',
    meta: {
      authRequired: true,
      scope: 'report-onhand-banquet-room',
    },
    component: () => import('./views/report/onhand-banquet-room/index'),
  },
  {
    path: '/report/industry',
    name: 'report-industry',
    meta: {
      authRequired: true,
      scope: 'report-industry',
    },
    component: () => import('./views/report/industry/index'),
  },
  {
    path: '/report/market-segment',
    name: 'report-market-segment',
    meta: {
      authRequired: true,
      scope: 'report-market-segment',
    },
    component: () => import('./views/report/segment/index'),
  },
  {
    path: '/report/venue-occupancy',
    name: 'report-venue-occupancy',
    meta: {
      authRequired: true,
      scope: 'report-venue-occupancy',
    },
    component: () => import('./views/report/venue/index'),
  },
  {
    path: '/report/sales-individual',
    name: 'report-sales-individual',
    meta: {
      authRequired: true,
      scope: 'report-sales-individual',
    },
    component: () => import('./views/report/sales/individual/index'),
  },
  {
    path: '/report/sales-activity',
    name: 'report-sales-activity',
    meta: {
      authRequired: true,
      scope: 'report-sales-activity',
    },
    component: () => import('./views/report/sales/activity/index'),
  },
  {
    path: '/report/sales-revenue',
    name: 'report-sales-revenue',
    meta: {
      authRequired: true,
      scope: 'report-sales-revenue',
    },
    component: () => import('./views/report/sales/revenue/index'),
  },
  {
    path: '/report/company-revenue',
    name: 'report-company-revenue',
    meta: {
      authRequired: true,
      scope: 'report-company-revenue',
    },
    component: () => import('./views/report/sales/company-revenue/index'),
  },
  {
    path: '/report/activity-summary',
    name: 'report-activity-summary',
    meta: {
      authRequired: true,
      scope: 'report-activity-summary',
    },
    component: () => import('./views/report/sales/activity-summary/index'),
  },
  {
    path: '/report/activities-summary',
    name: 'report-activities-summary',
    meta: {
      authRequired: true,
      scope: 'report-activities-summary',
    },
    component: () => import('./views/report/sales/activities-summary/index'),
  },
  {
    path: '/report/forecast-sales-revenue',
    name: 'report-forecast-sales-revenue',
    meta: {
      authRequired: true,
      scope: 'report-forecast-sales-revenue',
    },
    component: () => import('./views/report/forecast/sales-revenue/index'),
  },
  {
    path: '/report/forecast-property-revenue',
    name: 'report-forecast-property-revenue',
    meta: {
      authRequired: true,
      scope: 'report-forecast-property-revenue',
    },
    component: () => import('./views/report/forecast/property-revenue/index'),
  },
  {
    path: '/report/group-event-pace',
    name: 'report-group-event-pace',
    meta: {
      authRequired: true,
      scope: 'report-group-event-pace',
    },
    component: () => import('./views/report/group-event-pace/index'),
  },
  {
    path: '/report/banquet-breakdown',
    name: 'report-banquet-breakdown',
    meta: {
      authRequired: true,
      scope: 'report-banquet-breakdown',
    },
    component: () => import('./views/report/banquet/index'),
  },
  {
    path: '/report/group-data',
    name: 'report-group-data',
    meta: {
      authRequired: true,
      scope: 'report-group-data',
    },
    component: () => import('./views/report/group-data/index'),
  },
  {
    path: '/report/daily-revenue-by-status',
    name: 'report-daily-revenue-by-status',
    meta: {
      authRequired: true,
      scope: 'report-daily-revenue-by-status',
    },
    component: () => import('./views/report/daily-revenue-by-status/index'),
  },
  {
    path: '/industry',
    name: 'industry',
    meta: {
      authRequired: true,
      scope: 'industry',
    },
    component: () => import('./views/setup/industry/index'),
  },
  {
    path: '/position',
    name: 'position',
    meta: {
      authRequired: true,
      scope: 'position',
    },
    component: () => import('./views/setup/position/index'),
  },
  {
    path: '/cancel-reason',
    name: 'cancel-reason',
    meta: {
      authRequired: true,
      scope: 'cancel-reason',
    },
    component: () => import('./views/setup/cancel-reason/index'),
  },
  {
    path: '/area-zone',
    name: 'area-zone',
    meta: {
      authRequired: true,
      scope: 'area-zone',
    },
    component: () => import('./views/setup/zone-area/index'),
  },
  {
    path: '/segment',
    name: 'segment',
    meta: {
      authRequired: true,
      scope: 'segment',
    },
    component: () => import('./views/setup/segment/index'),
  },
  {
    path: '/additional',
    name: 'additional',
    meta: {
      authRequired: true,
      scope: 'additional',
    },
    component: () => import('./views/setup/additional/index'),
  },
  {
    path: '/food-stall',
    name: 'food-stall',
    meta: {
      authRequired: true,
      scope: 'food-stall',
    },
    component: () => import('./views/setup/food-stall/index'),
  },
  {
    path: '/sales-position',
    name: 'sales-position',
    meta: {
      authRequired: true,
      scope: 'sales-position',
    },
    component: () => import('./views/setup/sales-position/index'),
  },
  {
    path: '/event-type',
    name: 'event-type',
    meta: {
      authRequired: true,
      scope: 'event-type',
    },
    component: () => import('./views/setup/event-type/index'),
  },
  {
    path: '/event-note',
    name: 'event-note',
    meta: {
      authRequired: true,
      scope: 'event-note',
    },
    component: () => import('./views/setup/event-note/index'),
  },
  {
    path: '/package',
    name: 'package',
    meta: {
      authRequired: true,
      scope: 'package',
    },
    component: () => import('./views/setup/package/index'),
  },
  {
    path: '/meeting',
    name: 'meeting',
    meta: {
      authRequired: true,
      scope: 'meeting',
    },
    component: () => import('./views/setup/meeting/index'),
  },
  {
    path: '/meeting-rate',
    name: 'meeting-rate',
    meta: {
      authRequired: true,
      scope: 'meeting-rate',
    },
    component: () => import('./views/setup/meeting/rate'),
  },
  {
    path: '/meeting-calculation',
    name: 'meeting-calculation',
    meta: {
      authRequired: true,
      scope: 'meeting-calculation',
    },
    component: () => import('./views/setup/meeting/calculation'),
  },
  {
    path: '/meeting-inclusive',
    name: 'meeting-inclusive',
    meta: {
      authRequired: true,
      scope: 'meeting-inclusive',
    },
    component: () => import('./views/setup/meeting/inclusive'),
  },
  {
    path: '/sales',
    name: 'sales',
    meta: {
      authRequired: true,
      scope: 'sales',
    },
    component: () => import('./views/setup/sales/index'),
  },
  {
    path: '/sales-target',
    name: 'sales-target',
    meta: {
      authRequired: true,
      scope: 'sales-target',
    },
    component: () => import('./views/setup/sales/target'),
  },
  {
    path: '/sales-target-activity',
    name: 'sales-target-activity',
    meta: {
      authRequired: true,
      scope: 'sales-target-activity',
    },
    component: () => import('./views/setup/sales/target-activity'),
  },
  {
    path: '/meals',
    name: 'meals',
    meta: {
      authRequired: true,
      scope: 'meals',
    },
    component: () => import('./views/setup/meals/index'),
  },
  {
    path: '/property',
    name: 'property',
    meta: {
      authRequired: true,
      scope: 'property',
    },
    component: () => import('./views/setup/property/index'),
  },
  {
    path: '/property-mroom',
    name: 'property-mroom',
    meta: {
      authRequired: true,
      scope: 'property-mroom',
    },
    component: () => import('./views/setup/property/mroom'),
  },
  {
    path: '/property-room',
    name: 'property-room',
    meta: {
      authRequired: true,
      scope: 'property-room',
    },
    component: () => import('./views/setup/property/room'),
  },
  {
    path: '/property-target',
    name: 'property-target',
    meta: {
      authRequired: true,
      scope: 'property-target',
    },
    component: () => import('./views/setup/property/target'),
  },
  {
    path: '/property-layout',
    name: 'property-layout',
    meta: {
      authRequired: true,
      scope: 'property-layout',
    },
    component: () => import('./views/setup/property/layout'),
  },
  {
    path: '/rate-structure',
    name: 'rate-structure',
    meta: {
      authRequired: true,
      scope: 'rate-structure',
    },
    component: () => import('./views/setup/rate/index'),
  },
  {
    path: '/user-role',
    name: 'user-role',
    meta: {
      authRequired: true,
      scope: 'user-role',
    },
    component: () => import('./views/setup/user-role/index'),
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/user/profile/index'),
  },
  {
    path: '/room-rental',
    name: 'room-rental',
    meta: {
      authRequired: true,
      scope: 'room-rental',
    },
    component: () => import('./views/setup/room-rental/index'),
  },
  {
    path: '/room-rate',
    name: 'room-rate',
    meta: {
      authRequired: true,
      scope: 'room-rate',
    },
    component: () => import('./views/setup/room-rate/index'),
  },
  {
    path: '/user',
    name: 'user',
    meta: {
      authRequired: true,
      scope: 'user',
    },
    component: () => import('./views/setup/user/index'),
  },
  {
    path: '/menu',
    name: 'menu',
    meta: {
      authRequired: true,
      scope: 'menu',
    },
    component: () => import('./views/setup/menu/index'),
  },
  {
    path: '/sales-activity-diary',
    name: 'sales-activity-diary',
    meta: {
      authRequired: true,
      scope: 'sales-activity-diary',
    },
    component: () => import('./views/report/sales/activity-diary/index'),
  },
  {
    path: '/room',
    name: 'room',
    meta: {
      authRequired: true,
      scope: 'room',
    },
    component: () => import('./views/setup/room/index'),
  },
  {
    path: '/revert-letter',
    name: 'revert-letter',
    meta: {
      authRequired: true,
      scope: 'revert-letter',
    },
    component: () => import('./views/setup/letter/revert/index'),
  },
  {
    path: '/change-contact',
    name: 'change-contact',
    meta: {
      authRequired: true,
      scope: 'change-contact',
    },
    component: () => import('./views/setup/letter/modify/contact'),
  },
  {
    path: '/change-status',
    name: 'change-status',
    meta: {
      authRequired: true,
      scope: 'change-status',
    },
    component: () => import('./views/setup/letter/modify/status'),
  },
  {
    path: '/merge-data',
    name: 'merge-data',
    meta: {
      authRequired: true,
      scope: 'merge-data',
    },
    component: () => import('./views/setup/merge/index'),
  },
  {
    path: '/letter-calendar',
    name: 'letter-calendar',
    meta: {
      authRequired: true,
      scope: 'letter-calendar',
    },
    component: () => import('./views/qualifying/calendar/index'),
  },
]
