import FoodStallRateApi from "../../api/food-stall-rate";

export const state = {
  foodStallRateData: {},
  foodStallRateFilter: {},
}

export const mutations = {
  setFoodStallRateData(state, data) {
    state.foodStallRateData = data;
  },
  setFoodStallRateFilter(state, filter) {
    state.foodStallRateFilter = filter;
  },
}

export const actions = {
  async loadFoodStallRateData({ commit, state }) {
    const filter = state.foodStallRateFilter;
    return await FoodStallRateApi.getFoodStallRateList(filter).then((response) => {
      commit("setFoodStallRateData", response.data);
    });
  },
  submitFoodStallRateFilter({ commit }, filter) {
    commit("setFoodStallRateFilter", filter);
  },
}
