import AdditionalRateApi from "../../api/additional-rate";

export const state = {
  additionalRateData: {},
  additionalRateFilter: {},
}

export const mutations = {
  setAdditionalRateData(state, data) {
    state.additionalRateData = data;
  },
  setAdditionalRateFilter(state, filter) {
    state.additionalRateFilter = filter;
  },
}

export const actions = {
  async loadAdditionalRateData({ commit, state }) {
    const filter = state.additionalRateFilter;
    return await AdditionalRateApi.getAdditionalRateList(filter).then((response) => {
      commit("setAdditionalRateData", response.data);
    });
  },
  submitAdditionalRateFilter({ commit }, filter) {
    commit("setAdditionalRateFilter", filter);
  },
}
