import axios from "../plugins/axios";

const API_URL = "/api/menu";

class MenuApi {
  getMenuList(params) {
    return axios().get(API_URL, { params: params });
  }

  getMenus(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getMenuTree(params) {
    return axios().get(API_URL + `/tree`, { params: params });
  }

  getMenuRole(roleId) {
    return axios().get(API_URL + `/role/${roleId}`);
  }

  getMenu(menuId) {
    return axios().get(API_URL + `/${menuId}`);
  }

  createMenu(data) {
    return axios().post(API_URL, data);
  }

  updateMenu(menuId, data) {
    return axios().put(API_URL + `/${menuId}`, data);
  }

  deleteMenu(menuId) {
    return axios().delete(API_URL + `/${menuId}`);
  }
}

export default new MenuApi();
