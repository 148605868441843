import axios from "../plugins/axios";

const API_URL = "/api/rental-rate";

class RoomRentalApi {
  getRoomRentalList(params) {
    return axios().get(API_URL, { params: params });
  }

  getRoomRentals(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getRoomRental(roomRentalId) {
    return axios().get(API_URL + `/${roomRentalId}`);
  }

  createRoomRental(data) {
    return axios().post(API_URL, data);
  }

  updateRoomRental(roomRentalId, data) {
    return axios().put(API_URL + `/${roomRentalId}`, data);
  }
}

export default new RoomRentalApi();
