import axios from "../plugins/axios";

const API_URL = "/api/meeting";

class MeetingApi {
  getMeetingList(params) {
    return axios().get(API_URL, { params: params });
  }

  getMeetings(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getMeetingOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getMeeting(meetingId) {
    return axios().get(API_URL + `/${meetingId}`);
  }

  createMeeting(data) {
    return axios().post(API_URL, data);
  }

  updateMeeting(meetingId, data) {
    return axios().put(API_URL + `/${meetingId}`, data);
  }

  getFnbTypes(params) {
    return axios().get(`/api/fnbtype`, { params: params });
  }
}

export default new MeetingApi();
