import axios from "../plugins/axios";

const API_URL = "/api/package";

class PackageApi {
  getPackageList(params) {
    return axios().get(API_URL, { params: params });
  }

  getPackages(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getPackageOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getPackage(packageId) {
    return axios().get(API_URL + `/${packageId}`);
  }

  createPackage(data) {
    return axios().post(API_URL, data);
  }

  updatePackage(packageId, data) {
    return axios().put(API_URL + `/${packageId}`, data);
  }
}

export default new PackageApi();
