import MeetingRateApi from "../../api/meeting-rate";

export const state = {
  meetingRateData: {},
  meetingRateFilter: {},
}

export const mutations = {
  setMeetingRateData(state, data) {
    state.meetingRateData = data;
  },
  setMeetingRateFilter(state, filter) {
    state.meetingRateFilter = filter;
  },
}

export const actions = {
  async loadMeetingRateData({ commit, state }) {
    const filter = state.meetingRateFilter;
    return await MeetingRateApi.getMeetingRateList(filter).then((response) => {
      commit("setMeetingRateData", response.data);
    });
  },
  submitMeetingRateFilter({ commit }, filter) {
    commit("setMeetingRateFilter", filter);
  },
}
