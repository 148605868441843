import MealsSetApi from "../../api/meals-set";

export const state = {
  mealsSetData: {},
  mealsSetFilter: {},
}

export const mutations = {
  setMealsSetData(state, data) {
    state.mealsSetData = data;
  },
  setMealsSetFilter(state, filter) {
    state.mealsSetFilter = filter;
  },
}

export const actions = {
  async loadMealsSetData({ commit, state }) {
    const filter = state.mealsSetFilter;
    return await MealsSetApi.getMealsSetList(filter).then((response) => {
      commit("setMealsSetData", response.data);
    });
  },
  submitMealsSetFilter({ commit }, filter) {
    commit("setMealsSetFilter", filter);
  },
}
