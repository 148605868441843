import axios from "../plugins/axios";

const API_URL = "/api/additional";

class AdditionalApi {
  getAdditionalList(params) {
    return axios().get(API_URL, { params: params });
  }

  getAdditionals(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getAdditionalOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getAdditional(zoneId) {
    return axios().get(API_URL + `/${zoneId}`);
  }

  createAdditional(data) {
    return axios().post(API_URL, data);
  }

  updateAdditional(zoneId, data) {
    return axios().put(API_URL + `/${zoneId}`, data);
  }
}

export default new AdditionalApi();
