import SalesApi from "../../api/sales";

export const state = {
  salesData: {},
  salesFilter: {},
}

export const mutations = {
  setSalesData(state, data) {
    state.salesData = data;
  },
  setSalesFilter(state, filter) {
    state.salesFilter = filter;
  },
}

export const actions = {
  async loadSalesData({ commit, state }) {
    const filter = state.salesFilter;
    return await SalesApi.getSalesList(filter).then((response) => {
      commit("setSalesData", response.data);
    });
  },
  submitSalesFilter({ commit }, filter) {
    commit("setSalesFilter", filter);
  },
}
