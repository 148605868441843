import axios from "../plugins/axios";

const API_URL = "/api/definite";

class DefiniteApi {
  getDefiniteList(params) {
    return axios().get(API_URL, { params: params });
  }

  getDefinites(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getDefinite(definiteId) {
    return axios().get(API_URL + `/${definiteId}`);
  }

  printDefinite(definiteId) {
    return axios().get(API_URL + `/print/${definiteId}`, {
      responseType: "blob",
    });
  }

  createDefinite(data) {
    return axios().post(API_URL, data);
  }

  updateDefinite(definiteId, data) {
    return axios().put(API_URL + `/${definiteId}`, data);
  }

  deleteDefinite(definiteId) {
    return axios().delete(API_URL + `/${definiteId}`);
  }

  revertDefinite(definiteId) {
    return axios().post(API_URL + `/revert/${definiteId}`);
  }
}

export default new DefiniteApi();
