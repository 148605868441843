import axios from "../plugins/axios";

const API_URL = "/api/confirmation";

class ConfirmApi {
  getConfirmList(params) {
    return axios().get(API_URL, { params: params });
  }

  getConfirms(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getConfirm(confirmId) {
    return axios().get(API_URL + `/${confirmId}`);
  }

  getConfirmDeposit(confirmId) {
    return axios().get(API_URL + `/deposit/${confirmId}`);
  }

  printConfirm(confirmId) {
    return axios().get(API_URL + `/print/${confirmId}`, {
      responseType: "blob",
    });
  }

  createConfirm(data) {
    return axios().post(API_URL, data);
  }

  createConfirmDeposit(confirmId, data) {
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    return axios().post(API_URL + `/deposit/${confirmId}`, formData);
  }

  updateConfirm(confirmId, data) {
    return axios().put(API_URL + `/${confirmId}`, data);
  }

  cancelConfirm(confirmId, data) {
    return axios().put(API_URL + `/cancel/${confirmId}`, data);
  }

  definiteConfirm(confirmId, data) {
    return axios().put(API_URL + `/definite/${confirmId}`, data);
  }

  agreementConfirm(confirmId, data) {
    let formData = new FormData();
    for (var key in data) {
      formData.append(key, data[key]);
    }
    return axios().post(API_URL + `/agreement/${confirmId}`, formData);
  }

  deleteConfirm(confirmId) {
    return axios().delete(API_URL + `/${confirmId}`);
  }

  deleteConfirmDeposit(depositId) {
    return axios().delete(API_URL + `/deposit/${depositId}`);
  }

  revertConfirm(confirmId) {
    return axios().post(API_URL + `/revert/${confirmId}`);
  }

  revertCancel(confirmId, data) {
    return axios().put(API_URL + `/cancel/revert/${confirmId}`, data);
  }
}

export default new ConfirmApi();
