import RoomApi from "../../api/room";

export const state = {
  roomData: {},
  roomFilter: {},
}

export const mutations = {
  setRoomData(state, data) {
    state.roomData = data;
  },
  setRoomFilter(state, filter) {
    state.roomFilter = filter;
  },
}

export const actions = {
  async loadRoomData({ commit, state }) {
    const filter = state.roomFilter;
    return await RoomApi.getRoomList(filter).then((response) => {
      commit("setRoomData", response.data);
    });
  },
  submitRoomFilter({ commit }, filter) {
    commit("setRoomFilter", filter);
  },
}
