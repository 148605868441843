import axios from "../plugins/axios";

const API_URL = "/api/room-hotel";

class RoomApi {
  getRoomList(params) {
    return axios().get(API_URL, { params: params });
  }

  getRooms(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getRoom(roomId) {
    return axios().get(API_URL + `/${roomId}`);
  }

  async getRoomTypeOption(params) {
    return await axios()
      .get(API_URL + `/list`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  async getBedTypeOption(params) {
    return await axios()
      .get(`/api/bedtype`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
            pax: item.pax,
          });
        });

        return list;
      });
  }

  async getWeekTypeOption(params) {
    return await axios()
      .get(`/api/weektype`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  createRoom(data) {
    return axios().post(API_URL, data);
  }

  updateRoom(roomId, data) {
    return axios().put(API_URL + `/${roomId}`, data);
  }
}

export default new RoomApi();
