import EventTypeApi from "../../api/event-type";

export const state = {
  eventTypeData: {},
  eventTypeFilter: {},
}

export const mutations = {
  setEventTypeData(state, data) {
    state.eventTypeData = data;
  },
  setEventTypeFilter(state, filter) {
    state.eventTypeFilter = filter;
  },
}

export const actions = {
  async loadEventTypeData({ commit, state }) {
    const filter = state.eventTypeFilter;
    return await EventTypeApi.getEventTypeList(filter).then((response) => {
      commit("setEventTypeData", response.data);
    });
  },
  submitEventTypeFilter({ commit }, filter) {
    commit("setEventTypeFilter", filter);
  },
}
