import axios from "../plugins/axios";

const API_URL = "/api/property-mroom";

class PropertyMroomApi {
  getMroomList(params) {
    return axios().get(API_URL, { params: params });
  }

  getMrooms(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getMroomAvailable(params) {
    return axios().get(API_URL + `/available`, { params: params });
  }

  async getMroomOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getMroom(roomId) {
    return axios().get(API_URL + `/${roomId}`);
  }

  createMroom(data) {
    return axios().post(API_URL, data);
  }

  updateMroom(roomId, data) {
    return axios().put(API_URL + `/${roomId}`, data);
  }

  deleteMroom(roomId) {
    return axios().delete(API_URL + `/${roomId}`);
  }
}

export default new PropertyMroomApi();
