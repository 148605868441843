import AreaApi from "../../api/area";

export const state = {
  areaData: {},
  areaFilter: {},
}

export const mutations = {
  setAreaData(state, data) {
    state.areaData = data;
  },
  setAreaFilter(state, filter) {
    state.areaFilter = filter;
  },
}

export const actions = {
  async loadAreaData({ commit, state }) {
    const filter = state.areaFilter;
    return await AreaApi.getAreaList(filter).then((response) => {
      commit("setAreaData", response.data);
    });
  },
  submitAreaFilter({ commit }, filter) {
    commit("setAreaFilter", filter);
  },
}
