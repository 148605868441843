import PackageApi from "../../api/package";

export const state = {
  packageData: {},
  packageFilter: {},
}

export const mutations = {
  setPackageData(state, data) {
    state.packageData = data;
  },
  setPackageFilter(state, filter) {
    state.packageFilter = filter;
  },
}

export const actions = {
  async loadPackageData({ commit, state }) {
    const filter = state.packageFilter;
    return await PackageApi.getPackageList(filter).then((response) => {
      commit("setPackageData", response.data);
    });
  },
  submitPackageFilter({ commit }, filter) {
    commit("setPackageFilter", filter);
  },
}
