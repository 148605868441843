import CancelApi from "../../api/cancellation";

export const state = {
  confCancelData: {},
  confCancelFilter: {},
  offrCancelData: {},
  offrCancelFilter: {},
}

export const mutations = {
  setConfCancelData(state, data) {
    state.confCancelData = data;
  },
  setConfCancelFilter(state, filter) {
    state.confCancelFilter = filter;
  },
  setOffrCancelData(state, data) {
    state.offrCancelData = data;
  },
  setOffrCancelFilter(state, filter) {
    state.offrCancelFilter = filter;
  },
}

export const actions = {
  async loadConfCancelData({ commit, state }) {
    const filter = state.confCancelFilter;
    return await CancelApi.getCancelConfirms(filter).then((response) => {
      commit("setConfCancelData", response.data);
    });
  },
  submitConfCancelFilter({ commit }, filter) {
    commit("setConfCancelFilter", filter);
  },
  async loadOffrCancelData({ commit, state }) {
    const filter = state.offrCancelFilter;
    return await CancelApi.getCancelOfferings(filter).then((response) => {
      commit("setOffrCancelData", response.data);
    });
  },
  submitOffrCancelFilter({ commit }, filter) {
    commit("setOffrCancelFilter", filter);
  },
}
