import axios from "../plugins/axios";

const API_URL = "/api/user";

class UserApi {
  getUserList(params) {
    return axios().get(API_URL, { params: params });
  }

  getUsers(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getUser(userId) {
    return axios().get(API_URL + `/${userId}`);
  }

  createUser(data) {
    return axios().post(API_URL, data);
  }

  updateUser(userId, data) {
    return axios().put(API_URL + `/${userId}`, data);
  }

  deleteUser(userId) {
    return axios().delete(API_URL + `/${userId}`);
  }
}

export default new UserApi();
