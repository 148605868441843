import IndustryApi from "../../api/industry";

export const state = {
  industryData: {},
  industryFilter: {},
}

export const mutations = {
  setIndustryData(state, data) {
    state.industryData = data;
  },
  setIndustryFilter(state, filter) {
    state.industryFilter = filter;
  },
}

export const actions = {
  async loadIndustryData({ commit, state }) {
    const filter = state.industryFilter;
    return await IndustryApi.getIndustryList(filter).then((response) => {
      commit("setIndustryData", response.data);
    });
  },
  submitIndustryFilter({ commit }, filter) {
    commit("setIndustryFilter", filter);
  },
}
