import OtherActApi from "../../api/other-activity";

export const state = {
  otherActData: {},
  otherActFilter: {},
}

export const mutations = {
  setOtherActData(state, data) {
    state.otherActData = data;
  },
  setOtherActFilter(state, filter) {
    state.otherActFilter = filter;
  },
}

export const actions = {
  async loadOtherActData({ commit, state }) {
    const filter = state.otherActFilter;
    return await OtherActApi.getOtherActList(filter).then((response) => {
      commit("setOtherActData", response.data);
    });
  },
  submitOtherActFilter({ commit }, filter) {
    commit("setOtherActFilter", filter);
  },
}
