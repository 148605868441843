import Vue from "vue";
import { 
  currencyFormat,
  numberFormat,
  integerOnly,
} from "./numbers";

Vue.filter("currency", currencyFormat);
Vue.filter("number", numberFormat);
Vue.filter("integer", integerOnly);

Vue.filter("stripHTML", function(value) {
  const div = document.createElement("div");
  div.innerHTML = value;
  const text = div.textContent || div.innerText || "";
  return text;
});

Vue.filter("uppercase", function(value) {
  if (!value) return "";
  return value.toString().toUpperCase();
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("camelcase", function(value) {
  if (!value) return "";
  let text = value.toString().toLowerCase().split(" ");
  for (const idx in text) {
    text[idx] = text[idx].charAt(0).toUpperCase() + text[idx].slice(1);
  }
  return text.join(" ");
});

Vue.filter("ordinal", function(value) {
  value = parseInt(value);
  if (!value) return "";

  var s = ["th", "st", "nd", "rd"],
      v = value % 100;
  return value + (s[(v - 20) % 10] || s[v] || s[0]);
});