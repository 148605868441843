import axios from "../plugins/axios";

const API_URL = "/api/property";

class PropertyApi {
  getPropertyList(params) {
    return axios().get(API_URL, { params: params });
  }

  getProperties(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  async getPropertyOption(params) {
    return await axios()
      .get(API_URL + "/list", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  async getBrandOption(params) {
    return await axios()
      .get("/api/brand", {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  getProperty(propertyId) {
    return axios().get(API_URL + `/${propertyId}`);
  }

  createProperty(data) {
    return axios().post(API_URL, data);
  }

  updateProperty(propertyId, data) {
    return axios().put(API_URL + `/${propertyId}`, data);
  }

  deleteProperty(propertyId) {
    return axios().delete(API_URL + `/${propertyId}`);
  }

  getSetting(propertyId) {
    return axios().get(API_URL + `/setting/${propertyId}`);
  }

  getDepartment(params) {
    return axios().get(`/api/department/`, { params: params });
  }
}

export default new PropertyApi();
