import ZoneApi from "../../api/zone";

export const state = {
  zoneData: {},
  zoneFilter: {},
}

export const mutations = {
  setZoneData(state, data) {
    state.zoneData = data;
  },
  setZoneFilter(state, filter) {
    state.zoneFilter = filter;
  },
}

export const actions = {
  async loadZoneData({ commit, state }) {
    const filter = state.zoneFilter;
    return await ZoneApi.getZoneList(filter).then((response) => {
      commit("setZoneData", response.data);
    });
  },
  submitZoneFilter({ commit }, filter) {
    commit("setZoneFilter", filter);
  },
}
