import axios from "../plugins/axios";

const API_URL = "/api/rate-structure";

class RateApi {
  getRateList(params) {
    return axios().get(API_URL, { params: params });
  }

  getRates(params) {
    return axios().get(API_URL + `/list`, { params: params });
  }

  getRate(rateId) {
    return axios().get(API_URL + `/${rateId}`);
  }

  async getRateOption(params) {
    return await axios()
      .get(API_URL + `/list`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }

  createRate(data) {
    return axios().post(API_URL, data);
  }

  updateRate(rateId, data) {
    return axios().put(API_URL + `/${rateId}`, data);
  }

  async getMarketOption(params) {
    return await axios()
      .get(`/api/market`, {
        params: params,
      })
      .then((response) => {
        const list = [];
        const data = response.data ? response.data : [];
        data.forEach((item) => {
          list.push({
            value: item.id,
            text: item.name,
          });
        });

        return list;
      });
  }
}

export default new RateApi();
