import PropertyApi from "../../api/property";

export const state = {
  propertyData: {},
  propertyFilter: {},
}

export const mutations = {
  setPropertyData(state, data) {
    state.propertyData = data;
  },
  setPropertyFilter(state, filter) {
    state.propertyFilter = filter;
  },
}

export const actions = {
  async loadPropertyData({ commit, state }) {
    const filter = state.propertyFilter;
    return await PropertyApi.getPropertyList(filter).then((response) => {
      commit("setPropertyData", response.data);
    });
  },
  submitPropertyFilter({ commit }, filter) {
    commit("setPropertyFilter", filter);
  },
}
