import MeetingCalculationApi from "../../api/meeting-calculation";

export const state = {
  meetingCalculationData: {},
  meetingCalculationFilter: {},
}

export const mutations = {
  setMeetingCalculationData(state, data) {
    state.meetingCalculationData = data;
  },
  setMeetingCalculationFilter(state, filter) {
    state.meetingCalculationFilter = filter;
  },
}

export const actions = {
  async loadMeetingCalculationData({ commit, state }) {
    const filter = state.meetingCalculationFilter;
    return await MeetingCalculationApi.getMeetingCalculationList(filter).then((response) => {
      commit("setMeetingCalculationData", response.data);
    });
  },
  submitMeetingCalculationFilter({ commit }, filter) {
    commit("setMeetingCalculationFilter", filter);
  },
}
